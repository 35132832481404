import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"outlined":"","dense":_vm.dense,"append-icon":_vm.readonly ? null: 'fas fa-calendar-day',"label":_vm.label,"placeholder":_vm.label,"readonly":"","rules":_vm.rules,"hide-details":"auto"},model:{value:(_vm.dateString),callback:function ($$v) {_vm.dateString=$$v},expression:"dateString"}},_vm.readonly ? null : on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"range":_vm.range,"min":_vm.min,"max":_vm.max,"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[(_vm.range && _vm.showFromToday)?_c(VBtn,{attrs:{"outlined":"","text":"","color":"primary"},on:{"click":function($event){_vm.date = _vm.fromToday}}},[_vm._v("From Today ")]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.clearDate}},[_vm._v("Clear")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v("OK")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }