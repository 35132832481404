<template>
  <v-container fluid class="recurring-availability-grid pa-0">
    <v-app-bar v-model="showFloatingCategories"
               v-if="!hideHeader"
               fixed
               :class="['recurring-category-floating-header', {'app-bar-shown-md-and-up': appBarShown && $vuetify.breakpoint.mdAndUp}, {'app-bar-shown-sm-and-down': appBarShown && $vuetify.breakpoint.smAndDown}]"
               :height="categoryHeight"
               :style="floatCategoryStyle">
      <div class="overflow-x-auto scroll-wrapper">
        <div class="v-calendar v-calendar-daily v-calendar-category theme--light">
          <div class="v-calendar-daily__head">
            <div class="v-calendar-daily__intervals-head"></div>
            <div class="v-calendar-daily_head-day v-present">
              <div class="v-calendar-category__columns">
                <div v-for="category in categories"
                     :key="category"
                     class="v-calendar-category__column-header">
                  <v-container
                      :class="['availability-grid-category-container', 'fill-height', {'selected': selectedItemId === category}]"
                      @click="selectItem(category)">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-btn icon
                               @click.stop="$emit('click:category', {event: $event, item: itemMap[category], item_id: category})">
                          <v-icon color="rgb(40, 97, 169)">mdi-information-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <label>
                          {{ itemMap[category].name }}
                        </label>
                      </v-col>
                      <v-col cols="12"
                             v-if="itemMap[category].unavailableCount">
                        <label>
                          <v-icon color="red">
                            fas fa-exclamation-triangle
                          </v-icon>
                          {{ itemMap[category].unavailableCount }}
                        </label>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>

    <div class="d-flex justify-center">
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend UNAVAILABLE"><label></label>
        </div>
        <label>UNAVAILABLE</label>
      </div>
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend AVAILABLE"><label></label></div>
        <label>AVAILABLE</label>
      </div>
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend required-approval" style="background-color: var(--need-approval)">
          <label></label></div>
        <label>NEED APPROVAL</label>
      </div>
    </div>


    <v-row class="text-right" justify="end" v-if="Object.values(priceTierMap).length > 0">
      <v-col cols="12" md="4">
        <div v-for="tier in priceTierMap" v-bind:key="tier.id" class="text-right">
          <span style="font-weight: bold">{{ tier.legend }}</span> - {{ tier.name }} - ${{ tier.price }}
        </div>
      </v-col>
    </v-row>
    <v-row class="availability-grid-calendar">
      <v-col class="overflow-x-auto">
        <label v-if="!filteredSessions.length">NO SESSION AVAILABLE</label>
        <v-calendar type="category"
                    :interval-height="intervalHeight"
                    :interval-width="80"
                    :first-interval="firstInterval"
                    :interval-count="intervalCount"
                    :interval-minutes="intervalMinutes"
                    :interval-format="getIntervalFormat"
                    :start="calendarStart"
                    :end="calendarEnd"
                    :categories="categories"
                    :events="filteredSessions"
                    :event-color="getSessionColor"
                    @click:event="sessionClicked"
                    v-if="filteredSessions.length">
          <template v-slot:category="{category}">
            <v-container
                :class="['availability-grid-category-container', 'fill-height', {'selected': selectedItemId === category}]"
                @click="selectItem(category)">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn icon
                         @click.stop="$emit('click:category', {event: $event, item: itemMap[category], item_id: category})">
                    <v-icon color="rgb(40, 97, 169)">mdi-information-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <label>
                    {{ itemMap[category].name }}
                  </label>
                </v-col>
                <v-col cols="12"
                       v-if="itemMap[category].unavailableCount">
                  <label>
                    <v-icon color="red">
                      fas fa-exclamation-triangle
                    </v-icon>
                    {{ itemMap[category].unavailableCount }}
                  </label>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:event="{event}">
            <!--            <v-container class="font-weight-bold text-center">{{ event.price_tier ? priceTierMap[event.price_tier].legend : '' }}</v-container>-->
            <v-container
                :class="['font-weight-bold', 'text-center', 'pa-0', 'fill-height', 'flex-column', {'required-approval': event.require_approval}]">
              <v-row no-gutters class="flex-grow-1 d-flex" style="width: 100% !important;">
                <v-col class="availability-grid-event-content fill-height" style="">
                  {{ event.booking ? `${event.booking.title} - ${event.booking.account.name}` : '' }}
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-calendar>
        <!--            </v-sheet>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref, watch} from '@vue/composition-api';
import {DateTime} from "luxon";
import _ from "lodash";
import {SESSION_STATUS} from "@/constants";
import {ACTION_TYPES} from "@/store/types";
import $ from "jquery";
import Vue from 'vue';

export default {
  name: 'RecurringAvailabilityGrid',
  props: {
    startDate: {
      type: String,
      default: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    },
    endDate: {
      type: String,
      default: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    },
    startTime: String,
    endTime: String,
    recurrence: String,
    intervalMinutes: {
      type: Number,
      default: 1,
    },
    intervalHeight: {
      type: Number,
      default: 64,
    },
    extraParams: Object,
    showBookingDetails: Boolean,
    value: String,
    refreshTrigger: Boolean,
    hideHeader: Boolean,
  },
  setup(props, {root, emit}) {
    const appBarShown = computed(() => root.$store.getters.appBarShown);
    const showFloatingCategories = ref(false);
    const categoryHeaderX = ref(0);
    const categoryHeaderWidth = ref(0);
    const categoryHeight = ref(48);
    const floatCategoryStyle = computed(() => {
      return {
        left: `${categoryHeaderX.value - 1}px`,
        width: `${categoryHeaderWidth.value}px`,
      }
    })

    function onScroll(e) {
      let header = $('.availability-grid-calendar .v-calendar-daily__head');
      if (header[0]) header = header[0];
      if (header && header.getBoundingClientRect) {
        let rect = header.getBoundingClientRect();
        showFloatingCategories.value = rect.top <= 56;
        categoryHeight.value = rect.height;
        categoryHeaderX.value = rect.x;
        categoryHeaderWidth.value = rect.width;
      }
    }

    const throttled = _.throttle(onScroll, 100);

    const items = ref([]);
    const itemMap = computed(() => {
      let map = {};

      items.value.forEach((i) => {
        map[i.id] = i;
      });

      return map;
    })

    const priceTiers = ref([]);
    const priceTierMap = computed(() => {
      let map = {};

      priceTiers.value.forEach((i) => {
        map[i.id] = i;
      });

      return map;
    });

    const myAccountGroups = computed(() => {
      return root.$store.getters.account.account_groups;
    })

    const timeslots = ref([]);
    const sortedTimeslots = computed(() => {
      let timePeriods = new Set();
      timeslots.value.forEach((s) => {
        timePeriods.add(`${s.start_time} ${s.end_time}`);
      });
      return Array.from(timePeriods).sort().map((t) => {
        let components = t.split(' ');
        return {
          start: components[0],
          end: components[1],
        }
      });
    });
    const filteredSessions = computed(() => {
      const dayStart = DateTime.fromJSDate(new Date()).startOf('day');

      return timeslots.value.map((s) => {
        let idx = _.findIndex(sortedTimeslots.value, {start: s.start_time});

        return {
          ...s,
          category: s.item_id,
          start: dayStart.plus({minutes: idx}).toFormat('yyyy-MM-dd HH:mm'),
          end: dayStart.plus({minutes: idx + 1}).toFormat('yyyy-MM-dd HH:mm'),
          require_approval: _.some(s.sessions, 'require_approval'),
        }
      });
    });
    const categories = computed(() => {
      return _.sortBy(items.value, ['unavailableCount', 'name']).map((i) => i.id);
    });

    const firstInterval = computed(() => {
      return 0;
    });
    const intervalCount = computed(() => {
      return sortedTimeslots.value.length + 1;
    });
    const getIntervalFormat = function (time) {
      if (time.minute === 0) return '';

      let timeslot = sortedTimeslots.value[time.minute - 1];

      return `${root.$parseDate(timeslot.start)} - ${root.$parseDate(timeslot.end, 'HH:mm')}`;
    }

    const calendarStart = computed(() => {
      if (props.mode === 'day') {
        return props.startDate;
      }
    });
    const calendarEnd = computed(() => {
      if (props.mode === 'day') {
        return props.endDate;
      }
    })

    const getSessionColor = function (s) {
      return s.status;
    };

    const selectedSessions = ref([]);
    const selectedSessionIds = computed(() => selectedSessions.value.map((s) => s.id));
    watch(() => selectedSessionIds.value, (newValue) => {
      emit('input', newValue);
    })
    const sessionClicked = function ({event}) {
      selectItem(event.category);
    };

    const selectedItemId = ref(null);
    const selectItem = function (itemId) {
      selectedItemId.value = itemId;

      emit('input', selectedItemId.value);
    }

    const load = async function () {
      if (_.some([
        !props.startDate,
        !props.endDate,
        !props.startTime,
        !props.endTime,
        !props.recurrence,
      ])) {
        emit('update:refresh-trigger', false);
        return;
      }

      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/search_recurring/',
        params: {
          from_date: DateTime.fromFormat(`${props.startDate}`, 'yyyy-MM-dd').toISO(),
          to_date: DateTime.fromFormat(props.endDate, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO(),
          from_time: props.startTime,
          to_time: props.endTime,
          recurrence: props.recurrence,

          ...props.extraParams,
        }
      });

      // Process sessions
      let unavailableCount = {};

      timeslots.value = response.body.sessions.map((s) => {
        if (!(s.item_id in unavailableCount)) {
          unavailableCount[s.item_id] = 0;
        }

        let status = SESSION_STATUS.AVAILABLE;

        // Check if all overlapping sessions are available
        let all_available = true;

        // Check if timeslot has been covered by consecutive sessions
        let consecutive_start = null,
            consecutive_end = null;

        s.sessions.forEach((session) => {
          if (session.require_privilege && _.intersection(_.find(response.body.items, {id: s.item_id}).privileged_groups, myAccountGroups.value).length === 0) {
            all_available = false;
          }

          if (session.status !== SESSION_STATUS.AVAILABLE) {
            all_available = false;
          }

          if (consecutive_start === null) {
            consecutive_start = session.start;
            consecutive_end = session.end;
          } else if (session.start === consecutive_end) {
            consecutive_end = session.end;
          }
        })

        if (!all_available || (consecutive_start > s.start) || (consecutive_end < s.end) || !s.sessions.length) {
          unavailableCount[s.item_id] += 1;
          status = SESSION_STATUS.OCCUPIED;
        }

        return {
          ...s,
          category: s.item_id,
          start_time: s.start,
          end_time: s.end,
          start: root.$parseDate(s.start, 'yyyy-MM-dd HH:mm'),
          end: root.$parseDate(s.end, 'yyyy-MM-dd HH:mm'),
          status,
        }
      });

      items.value = _.sortBy(response.body.items.map((item) => {
        return {
          ...item,
          unavailableCount: unavailableCount[item.id],
        }
      }), ['unavailableCount', 'name']);
      priceTiers.value = response.body.price_tiers;

      Vue.nextTick(() => {
        let headers = $('.v-calendar-category__columns');

        headers.scroll((e) => {
          headers.scrollLeft(e.target.scrollLeft);
        });
      });

      emit('update:refresh-trigger', false);
    }

    watch(() => props.refreshTrigger, (newValue) => {
      if (newValue) {
        load();
      }
    }, {immediate: true});

    onMounted(() => {
      window.addEventListener('scroll', throttled);
      window.addEventListener('resize', throttled);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', throttled);
      window.removeEventListener('resize', throttled);
    })

    return {
      appBarShown,
      showFloatingCategories,
      categoryHeight,
      floatCategoryStyle,


      itemMap,

      priceTierMap,

      filteredSessions,
      categories,

      firstInterval,
      intervalCount,
      getIntervalFormat,

      calendarStart,
      calendarEnd,

      getSessionColor,

      selectedSessions,
      sessionClicked,

      selectedItemId,
      selectItem,
    }
  },
}
</script>

<style lang="less">
.recurring-availability-grid {
  .availability-grid-category-container {
    cursor: pointer;
    border: solid 5px transparent;

    &.selected {
      border-color: #998065;
    }
  }

  .v-calendar-daily__scroll-area::-webkit-scrollbar {
    display: none;
  }

  .v-event-timed-container {
    margin-right: 0 !important;
  }

  .availability-grid-legend-col {
    flex: 0 0 auto;
    position: relative;
    padding: 0;
    padding-left: 62px;
    text-align: left;
    margin-right: 12px;
    margin-bottom: 12px;

    label {
      line-height: 40px;
    }
  }

  .SELECTED {
    background: var(--selected);

    .required-approval {
      background: var(--need-approval-selected);
    }
  }

  .AVAILABLE {
    background-color: var(--available);

    .required-approval {
      background: var(--need-approval);
    }
  }

  .UNAVAILABLE, .OCCUPIED, .EXCLUDED, .EXPIRED {
    background-color: var(--unavailable);
  }

  .availability-grid-event-content {
    white-space: pre-wrap;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }

  .availability-grid-legend {
    display: inline-block;
    width: 45px;
    height: 45px;
    /*max-width: 11vw;*/
    max-height: 11vw;
    /*line-height: 11vw;*/
    color: white;

    position: absolute;
    left: 12px;
    top: 0;
    padding: 0;

    label {
      margin: 0;
      //position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media only screen and (max-width: 600px) {
        font-size: 0.9rem;
      }
    }
  }

  .v-calendar-daily_head-weekday, .v-calendar-daily_head-day-label {
    display: none;
  }

  .v-calendar-category .v-calendar-category__columns {
    overflow-x: auto;

    .v-calendar-category__column {
      min-width: 150px;
    }
  }

  .v-calendar-daily_head-day .v-calendar-category__columns .v-calendar-category__column-header {
    min-width: 150px;
  }

  .v-calendar-daily__interval-text {
    top: -48px;
  }
}

.recurring-category-floating-header {
  top: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  transition: none;
  z-index: 4 !important;

  &.app-bar-shown-md-and-up {
    top: 64px !important;
  }

  &.app-bar-shown-sm-and-down {
    top: 56px !important;
  }

  .scroll-wrapper {
    width: 100%;
    //padding-left: 36px;
    //padding-right: 36px;
  }

  .v-toolbar__content {
    background-color: transparent;
    padding: 0;
  }

  .v-calendar-daily__head {
    margin-right: 0;

    .v-calendar-daily__intervals-head {
      width: 80px;
    }
  }
}
</style>
