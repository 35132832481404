import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,{staticStyle:{"height":"70vh","overflow":"auto"},domProps:{"innerHTML":_vm._s(_vm.content)}}),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }