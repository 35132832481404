<template>
  <v-card class="edit-booking-additional-item-dialog">
    <v-card-title>
      Update Booking Additional Items
    </v-card-title>
    <v-card-text style="height: 70vh;">
      <v-form ref="form">
        <v-row>
          <v-col v-for="bookingAdditionalItem in bookingAdditionalItems"
                 :key="bookingAdditionalItem.id"
                 cols="12">
            <v-input hide-details="auto"
                     v-model="values[bookingAdditionalItem.id]">
              <!--TEXT-->
              <v-text-field outlined
                            clearable
                            :rules="bookingAdditionalItem.item_type_additional_item.required ? requiredRules : []"
                            hide-details="auto"
                            :placeholder="bookingAdditionalItem.item_type_additional_item.name"
                            :label="bookingAdditionalItem.item_type_additional_item.name"
                            :hint="bookingAdditionalItem.item_type_additional_item.hint"
                            :persistent-hint="Boolean(bookingAdditionalItem.item_type_additional_item.hint)"
                            v-model="values[bookingAdditionalItem.id]"
                            v-if="bookingAdditionalItem.item_type_additional_item.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.TEXT"></v-text-field>

              <!--NUMBER-->
              <v-text-field outlined
                            clearable
                            :rules="bookingAdditionalItem.item_type_additional_item.required ? requiredRules : []"
                            hide-details="auto"
                            type="number"
                            min="0"
                            :placeholder="bookingAdditionalItem.item_type_additional_item.name"
                            :label="bookingAdditionalItem.item_type_additional_item.name"
                            :hint="bookingAdditionalItem.item_type_additional_item.hint"
                            :persistent-hint="Boolean(bookingAdditionalItem.item_type_additional_item.hint)"
                            v-model="values[bookingAdditionalItem.id]"
                            v-if="bookingAdditionalItem.item_type_additional_item.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.NUMBER"></v-text-field>

              <!--DATE-->
              <DatePickerInput :label="bookingAdditionalItem.item_type_additional_item.name"
                               :hint="bookingAdditionalItem.item_type_additional_item.hint"
                               :persistent-hint="Boolean(bookingAdditionalItem.item_type_additional_item.hint)"
                               :rules="bookingAdditionalItem.item_type_additional_item.required ? requiredRules : []"
                               v-model="values[bookingAdditionalItem.id]"
                               v-if="bookingAdditionalItem.item_type_additional_item.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.DATE"></DatePickerInput>

              <!--TIME-->
              <TimePickerInput :label="bookingAdditionalItem.item_type_additional_item.name"
                               :hint="bookingAdditionalItem.item_type_additional_item.hint"
                               :persistent-hint="Boolean(bookingAdditionalItem.item_type_additional_item.hint)"
                               hide-details="auto"
                               :rules="bookingAdditionalItem.item_type_additional_item.required ? requiredRules : []"
                               use-native
                               v-model="values[bookingAdditionalItem.id]"
                               v-if="bookingAdditionalItem.item_type_additional_item.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.TIME"></TimePickerInput>

              <!--BOOLEAN-->
              <v-checkbox :label="bookingAdditionalItem.item_type_additional_item.name"
                          :hint="bookingAdditionalItem.item_type_additional_item.hint"
                          :persistent-hint="Boolean(bookingAdditionalItem.item_type_additional_item.hint)"
                          hide-details="auto"
                          v-model="values[bookingAdditionalItem.id]"
                          v-if="bookingAdditionalItem.item_type_additional_item.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.BOOLEAN"></v-checkbox>

              <!--OPTION-->
              <v-select outlined
                        :label="bookingAdditionalItem.item_type_additional_item.name"
                        :hint="bookingAdditionalItem.item_type_additional_item.hint"
                        :persistent-hint="Boolean(bookingAdditionalItem.item_type_additional_item.hint)"
                        hide-details="auto"
                        :rules="bookingAdditionalItem.item_type_additional_item.required ? requiredRules : []"
                        :items="getItemTypeAdditionalItemOptions(bookingAdditionalItem.item_type_additional_item)"
                        v-model="values[bookingAdditionalItem.id]"
                        v-if="bookingAdditionalItem.item_type_additional_item.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.OPTIONS"></v-select>
            </v-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text
             color="primary"
             @click="$emit('submit')">
        {{ $t('cancel') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text
             color="primary"
             @click="$refs.form.validate() && save()">
        {{ $t('ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DatePickerInput from "@/components/DatePickerInput";
import TimePickerInput from "@/components/TimePickerInput";
import {ITEM_TYPE_ADDITIONAL_ITEM_TYPE} from "@/constants";
import {onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import _ from "lodash";

export default {
  name: 'EditBookingAdditionalItemDialog',
  components: {TimePickerInput, DatePickerInput},
  props: {
    booking: Object,
  },
  setup(props, {root, emit}) {
    const bookingAdditionalItems = ref([]);

    const values = ref({});
    const requiredRules = ref([
      value => !!value || 'Required',
    ]);
    const getItemTypeAdditionalItemOptions = function (itemTypeAdditionalItem) {
      if (itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.OPTIONS) {
        let meta = JSON.parse(itemTypeAdditionalItem.meta);

        return meta.options.map((o) => {
          return {text: o, value: o};
        });
      }

      return [];
    }

    const save = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.item_admin_edit_additional_item_confirm_msg'), showClose: false})) {
        console.log(values.value);
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/managed_items/edit_additional_item/',
          params: {
            booking_id: props.booking.id,
            additional_items: values.value,
          },
        })

        if (response.body.success) {
          root.$showAlertMessage(root.$t('booking.item_admin_edit_additional_item_success_msg'));
          emit('submit', true);
        }
      }
    }

    onMounted(() => {
      bookingAdditionalItems.value = _.sortBy([...props.booking.additional_items], (i) => i.item_type_additional_item.ordering);
      props.booking.additional_items.forEach((a) => {
        values.value[a.id] = a.value;
      })

    })

    return {
      bookingAdditionalItems,
      values,
      requiredRules,
      ITEM_TYPE_ADDITIONAL_ITEM_TYPE,
      getItemTypeAdditionalItemOptions,
      save,
    }
  },
}
</script>