import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:['remote-paginated-list', 'pa-0', {'full-height': _vm.fullHeight}],attrs:{"fluid":""}},[(!_vm.hideSearch)?_c(VRow,{staticClass:"remote-paginated-list--filter-row",attrs:{"no-gutters":""}},[_c(VCol,[_c('AdvancedSearchInput',{attrs:{"filter-options":_vm.filterOptions,"exclude-filter-fields":_vm.excludedFilterFields},on:{"filter":_vm.onFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c(VDataTable,_vm._g(_vm._b({staticClass:"remote-paginated-list--table",attrs:{"loading":_vm.isLoading,"items":_vm.items,"server-items-length":_vm.itemCount,"search":_vm.search,"options":_vm.tableOptions,"footer-props":{itemsPerPageOptions: [10, 20, 50]},"fixed-header":_vm.fullHeight,"single-select":_vm.singleSelect},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.onTableOptionUpdated]},scopedSlots:_vm._u([(!_vm.singleSelect)?{key:"header.data-table-select",fn:function(){return [_c(VSimpleCheckbox,{attrs:{"value":_vm.selectedItemIds.length === _vm.itemCount,"ripple":false},on:{"input":_vm.selectedAllToggled}})]},proxy:true}:null,(!_vm.singleSelect)?{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c(VSimpleCheckbox,{key:item.id,attrs:{"value":_vm.selectedItemIds.indexOf(item.id) !== -1,"ripple":false},on:{"input":function($event){return _vm.itemSelected(item)}}})]}}:null,_vm._l((_vm.tableTemplateKeys),function(key){return {key:key,fn:function(context){return [_vm._t(key,null,null,context)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }