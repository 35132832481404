import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"menu",attrs:{"persistent":"","return-value":_vm.time,"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"outlined":"","append-icon":_vm.readonly || _vm.useNative ? null: 'fas fa-clock',"label":_vm.label,"placeholder":_vm.label,"readonly":!_vm.useNative,"hide-details":_vm.hideDetails,"clearable":"","type":"time","rules":_vm.rules},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},_vm.readonly || _vm.useNative ? null : on))]}}]),model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_c(VTimePicker,{attrs:{"format":"24hr","scrollable":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showTimePicker = false}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.clearTime}},[_vm._v("Clear")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.time)}}},[_vm._v("OK")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }