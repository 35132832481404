import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BannerFrame',{attrs:{"label":_vm.$t('app_drawer.book_facilities')}},[_c(VContainer,{staticClass:"select-type"},[_c(VRow,_vm._l((_vm.itemTypes),function(itemType){return _c(VCol,{key:itemType.id,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{on:{"click":function($event){return _vm.$router.push({name: 'search', params: {itemTypeId: itemType.id}})}}},[_c(VImg,{staticClass:"white--text align-end",attrs:{"src":_vm.$getURL(itemType.banner),"gradient":hover ? null: 'to bottom, rgba(0,0,0,.01), rgba(0,0,0,.7)',"height":"300px"}},[_c(VCardTitle,{staticClass:"text-left text-break"},[_vm._v(_vm._s(itemType.name))])],1)],1)]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }