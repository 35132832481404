import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import SelectType from "@/views/SelectType";
import Search from "@/views/Search";
import BookingDetail from "@/views/BookingDetail";
import Dashboard from "@/views/Dashboard";
import PendingApproval from "@/views/PendingApproval";
import RegisterDevice from "@/views/RegisterDevice";
import MyInvitations from "@/views/MyInvitations";
import InvitationDetail from "@/views/InvitationDetail";
import ScanQrCode from "@/views/ScanQrCode";
import store from '@/store';
import i18n from "@/plugins/i18n";
import {ACTION_TYPES} from "@/store/types";
import Schedule from "@/views/Schedule";
import BookingRecordDetail from "@/views/BookingRecordDetail";
import ManagedItems from "@/views/ManagedItems";
import ItemSchedule from "@/views/ItemSchedule";
import ApproveBooking from "@/views/ApproveBooking";
import MyBookings from "@/views/MyBookings";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login/:autoLogin?',
        name: 'login',
        component: Login,
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },

    {
        path: '/',
        name: 'select-type',
        component: SelectType
    },
    {
        path: '/search/:itemTypeId',
        name: 'search',
        component: Search,
    },

    {
        path: '/schedule',
        name: 'schedule',
        component: Schedule,
    },
    {
        path: '/item_schedule/:itemId?',
        name: 'item-schedule',
        component: ItemSchedule,
    },
    {
        path: '/bookings',
        name: 'my-bookings',
        component: MyBookings,
        // component: MyBookingRecords,
    },
    {
        path: '/booking_record/:bookingRecordId',
        name: 'booking-record-details',
        component: BookingRecordDetail,
    },
    {
        path: '/booking/:bookingId',
        name: 'booking-details',
        component: BookingDetail,
    },

    {
        path: '/invitations',
        name: 'my-invitations',
        component: MyInvitations,
    },
    {
        path: '/invitation/:invitationId',
        name: 'invitation-details',
        component: InvitationDetail,
    },


    {
        path: '/approvals/:itemTypeId',
        name: 'pending-approval',
        component: PendingApproval,
    },
    {
        path: '/approve/:bookingId',
        name: 'approve-booking',
        component: ApproveBooking,
    },

    {
        path: '/managed_items',
        name: 'managed-items',
        component: ManagedItems,
    },

    {
        path: '/scan/:token?',
        name: 'scan-qr-code',
        component: ScanQrCode,
    },

    {
        path: '/register_device',
        name: 'register-device',
        component: RegisterDevice,
    },
]

const router = new VueRouter({
    routes
})

router.afterEach((to, from) => {
    store.dispatch(ACTION_TYPES.SET_APP_BAR_TITLE, {
        'select-type': i18n.t('app_drawer.book_facilities'),
        'search': i18n.t('app_drawer.book_facilities'),
        'schedule': i18n.t('app_drawer.my_schedule'),
        'item-schedule': i18n.t('app_drawer.item_schedule'),
        'my-bookings': i18n.t('app_drawer.my_bookings'),
        'booking-details': i18n.t('app_drawer.my_bookings'),
        'my-invitations': i18n.t('app_drawer.my_invitations'),
        'managed-items': i18n.t('app_drawer.managed_items'),
        'invitation-details': i18n.t('app_drawer.my_invitations'),
        'pending-approval': i18n.t('app_drawer.pending_approval'),
    }[to.name]);
})

export default router
