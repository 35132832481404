import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title || 'Select Date')+" ")]),_c(VCardText,[_c('DatePickerInput',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c(VCardActions,{staticClass:"text-right"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c(VBtn,{attrs:{"text":"","color":"primary","disabled":!_vm.date},on:{"click":function($event){return _vm.$emit('submit', _vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }