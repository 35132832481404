<template>
  <v-card>
    <v-card-title>
      {{ title || 'Select Date' }}
    </v-card-title>
    <v-card-text>
      <DatePickerInput v-model="date"></DatePickerInput>
    </v-card-text>
    <v-card-actions class="text-right">
      <v-spacer></v-spacer>
      <v-btn text
             @click="$emit('submit', false)">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn text
             color="primary"
             :disabled="!date"
             @click="$emit('submit', date)">
        {{ $t('ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {onMounted, ref} from '@vue/composition-api'
import DatePickerInput from "@/components/DatePickerInput";

export default {
  name: 'DateInputDialog',
  components: {DatePickerInput},
  props: {
    title: String,
    prefill: String,
  },
  setup(props) {
    const date = ref(null);

    onMounted(() => {
      if (props.prefill) {
        date.value = props.prefill;
      }
    })

    return {
      date,
    }
  }
}
</script>
