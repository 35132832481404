<template>
  <v-container fluid v-if="processedFilterOptions.length">
    <v-row no-gutters>
      <v-col class="text-right">
        <v-spacer></v-spacer>
        <v-btn color="primary"
               depressed
               tile
               @click="onFilterModalShown">
          <v-icon>fas fa-filter</v-icon>
          <span v-if="filterCount">({{ filterCount }})</span>
        </v-btn>
      </v-col>
    </v-row>

    <BGModal :shown="filterModalShown"
             :title="$t('filter')">
      <template #content>
        <v-container>
          <v-row>
            <v-col cols="12" md="6" lg="4"
                   v-for="option in processedFilterOptions">
              <v-combobox :items="sortedOptions(option)"
                          :label="option.text"
                          outlined
                          chips
                          small-chips
                          :multiple="!option.single"
                          clearable
                          hide-details
                          v-if="option.options"
                          :value="tempDisplayValues[option.value]"
                          @change="onComboboxInput(option, $event)"></v-combobox>
              <DatePickerInput range
                               hide-details
                               :label="option.text + (option.date_range ? ` (${$t('date_range')})`: '')"
                               v-if="option.date_range"
                               :value="tempFilters[option.value] ? tempFilters[option.value].value : []"
                               @input="onDatePickerInput(option, $event)"></DatePickerInput>
              <v-text-field outlined
                            hide-details
                            :label="option.text"
                            v-if="option.contains || option.number"
                            :type="option.number ? 'number' : null"
                            min="0"
                            :value="tempFilters[option.value]"
                            @input="onTexFieldInput(option, $event)"></v-text-field>
              <v-switch :label="option.text"
                        v-if="option.hasOwnProperty('boolean')"
                        @change="onCheckboxInput(option, $event)"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #footer>
        <v-row class="px-4">
          <v-btn @click="filterModalShown = false"
                 class="mr-3"
                 depressed>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="clearTempFilters"
                 class="mr-3"
                 depressed>
            {{ $t('clear') }}
          </v-btn>
          <v-btn color="primary"
                 depressed
                 @click="onFilterModalFinish">
            {{ $t('apply') }} <span v-if="tempFilterCount">({{ tempFilterCount }})</span>
            <v-icon right>fas fa-check</v-icon>
          </v-btn>
        </v-row>
      </template>
    </BGModal>
  </v-container>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import _ from 'lodash';
import DatePickerInput from "./DatePickerInput";
import BGModal from "@/components/BGModal";

export default {
  name: 'AdvancedSearchInput',
  components: {BGModal, DatePickerInput},
  props: {
    value: String,
    filterOptions: Array,
    excludeFilterFields: Array,
  },
  setup(props, {emit}) {
    const search = ref(props.value);
    watch(
        search,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            emit('input', newVal)
          }
        }
    );

    const processedFilterOptions = computed(() => {
      let options = [];
      let excludeFilterFields = props.excludeFilterFields || [];

      if (props.filterOptions) {
        options = _.filter(
            props.filterOptions,
            (o) => excludeFilterFields.indexOf(o.value) === -1 && _.some(['options', 'date_range', 'contains', 'boolean', 'number'], (k) => o.hasOwnProperty(k)),
        );
      }

      return options;
    });
    const sortedOptions = function (option) {
      return _.sortBy(option.options, 'text')
    }
    const showFilterMenu = ref(null);
    const filters = ref({});
    const filterCount = ref(0);
    const displayValues = ref({});

    const filterModalShown = ref(false);
    const tempFilters = ref({});
    const tempFilterCount = ref(0);
    const tempDisplayValues = ref({});
    const onFilterModalShown = function () {
      tempFilters.value = {...filters.value};
      tempFilterCount.value = Object.keys(tempFilters.value).length;
      tempDisplayValues.value = {...displayValues.value};
      filterModalShown.value = true;
    };
    const onFilterModalFinish = function () {
      filters.value = {...tempFilters.value};
      filterCount.value = Object.keys(filters.value).length;
      displayValues.value = {...tempDisplayValues.value};
      filterModalShown.value = false;
      emit('filter', filters.value);
    };
    const onComboboxInput = function (option, value) {
      let existingDisplayValues = tempDisplayValues.value;
      let existingFilters = tempFilters.value;
      if (option.single) {
        if (value) {
          existingDisplayValues[option.value] = value;
          existingFilters[option.value] = typeof value === "string" ? value : value.value;
        } else {
          delete existingDisplayValues[option.value];
          delete existingFilters[option.value];
        }
      } else {
        if (value.length) {
          existingDisplayValues[option.value] = value;
          existingFilters[option.value] = value.map(v => typeof v === "string" ? v : v.value);
        } else {
          delete existingDisplayValues[option.value];
          delete existingFilters[option.value];
        }
      }

      tempDisplayValues.value = existingDisplayValues;
      tempFilters.value = existingFilters;

      tempFilterCount.value = Object.keys(tempFilters.value).length;
    };
    const onDatePickerInput = function (option, value) {
      let existingFilters = tempFilters.value;
      if (option.date_range && value.length === 2) {
        existingFilters[option.value] = {date_range: true, 'value': value};
      } else {
        delete existingFilters[option.value];
      }

      tempFilters.value = existingFilters;

      tempFilterCount.value = Object.keys(tempFilters.value).length;
    }
    const onTexFieldInput = function (option, value) {
      let existingFilters = tempFilters.value;
      if (value.length) {
        existingFilters[option.value] = value;
      } else {
        delete existingFilters[option.value];
      }

      tempFilters.value = existingFilters;

      tempFilterCount.value = Object.keys(tempFilters.value).length;
    }
    const onCheckboxInput = function (option, value) {
      let existingFilters = tempFilters.value;
      if (value) {
        existingFilters[option.value] = option.boolean;
      } else {
        delete existingFilters[option.value];
      }

      tempFilters.value = existingFilters;

      tempFilterCount.value = Object.keys(tempFilters.value).length;
    }
    const clearTempFilters = function () {
      tempDisplayValues.value = {};
      tempFilters.value = {};
      tempFilterCount.value = 0;
    };

    // const applyFilters = function () {
    //   emit('filter', filters.value);
    //   showFilterMenu.value = false;
    // };
    // const clearFilters = function () {
    //   displayValues.value = {};
    //   filters.value = {};
    //   filterCount.value = 0;
    //   applyFilters();
    // };

    return {
      search,
      processedFilterOptions,
      sortedOptions,
      showFilterMenu,
      filters,
      filterCount,
      displayValues,

      filterModalShown,
      tempFilters,
      tempFilterCount,
      tempDisplayValues,
      onFilterModalShown,
      onFilterModalFinish,
      onComboboxInput,
      onDatePickerInput,
      onTexFieldInput,
      onCheckboxInput,
      clearTempFilters,
      // applyFilters,
      // clearFilters,
    };
  },
}
</script>

<style lang="less">
.advanced-search-input {
  .v-input__slot {
    padding-right: 0 !important;

    .v-input__append-inner {
      margin-top: 2px;
      margin-left: 0;
      margin-bottom: 0;
      /*padding-right: 53px;*/
    }
  }

  .advanced-search-filter-btn {
    /*padding: 0 !important;*/
  }

  .advanced-search-input-icon {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
