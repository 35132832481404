<template>
  <div class="multiple-account-input">
    <v-autocomplete outlined
                    :hide-details="!hint"
                    :label="label"
                    :hint="hint"
                    persistent-hint
                    :items="accounts"
                    v-model="dummySelected"
                    v-if="!readonly"
                    multiple
                    return-object
                    :search-input.sync="searchInput"
                    @change="onAccountSelect"
                    :item-text="accountText"
                    :menu-props="{closeOnContentClick: true}">
      <template #selection></template>
      <template #item="{parent, item}">
        <v-list-item-content class="text-left">
          <v-list-item-title v-html="parent.genFilteredText(item.name)"></v-list-item-title>
          <!--              <v-list-item-subtitle v-text="account.username"></v-list-item-subtitle>-->
          <v-list-item-subtitle v-if="item.title"
                                v-html="parent.genFilteredText(item.title)">
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.mobile"
                                v-html="parent.genFilteredText(item.mobile)">
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.email"
                                v-html="parent.genFilteredText(item.email)">
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-row class="multiple-account-input-list" no-gutters>
      <v-col v-for="(account, idx) in selectedAccounts"
             cols="12" md="6">
        <v-list-item class="multiple-account-input-invitation-item px-0">
          <v-list-item-avatar v-if="!readonly">
            <v-btn icon
                   v-if="showClear(account)"
                   @click="removeAccount(idx)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content class="text-left">
            <v-list-item-title>
              {{ account.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-for="field in filteredFields(account)">
              {{ field }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {User} from "@/store/models";
import _ from "lodash";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'MultipleAccountInput',
  props: {
    value: Array,
    label: String,
    hint: String,
    fields: {
      type: Array,
      default: () => {
        return ['title', 'mobile', 'email'];
      },
    },
    readonly: Boolean,
    showClear: {
      type: Function,
      default: (a) => true,
    },
    accountText: {
      type: Function,
      default: (a) => `${a.name} ${a.title ? '(' + a.title + ')' : ''} <${a.username}>`
    }
  },
  setup(props, {root, emit}) {
    const allAccounts = computed(() => User.query().orderBy('name').get());
    const dummySelected = ref([]);
    const accountFilter = function (a) {
      return props.value.indexOf(a.id) === -1;
    }
    const accounts = computed(() => {
      return _.filter(allAccounts.value, accountFilter).map((a) => {
        return {
          ...a,
          value: a,
        }
      });
    });

    const searchInput = ref('');
    const onAccountSelect = function (evt) {
      let selectedAccount = evt[0];
      selectedAccounts.value.push(selectedAccount);
      dummySelected.value.length = 0;
      searchInput.value = '';
    }

    const selectedAccounts = ref([]);
    watch(() => props.value, (newValue, oldValue) => {
      if (_.xor(newValue, oldValue).length) {
        selectedAccounts.value = _.filter(allAccounts.value, (a) => newValue.indexOf(a.id) !== -1)
      }
    }, {immediate: true})
    watch(() => selectedAccounts.value, (newValue, oldValue) => {
      emit('input', newValue.map((a) => a.id));
    })
    const filteredFields = function (account) {
      let results = [];

      props.fields.forEach((f) => {
        if (account[f]) {
          results.push(account[f]);
        }
      })

      return results;
    }
    const removeAccount = function (idx) {
      selectedAccounts.value.splice(idx, 1);
    }

    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.GET_USERS, {});
    })

    return {
      dummySelected,
      accounts,
      searchInput,
      onAccountSelect,
      selectedAccounts,
      filteredFields,
      removeAccount,
    }
  },
}
</script>
