import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"color":"#FFFFFF","app":"","width":"320","right":_vm.right,"touchless":"","id":"appDrawer"},model:{value:(_vm.drawerShown),callback:function ($$v) {_vm.drawerShown=$$v},expression:"drawerShown"}},[_c(VContainer,{staticClass:"app-drawer-container",attrs:{"fluid":""}},[(_vm.account)?_c(VListItem,{staticClass:"account-info",attrs:{"link":"","to":{name: 'scan-qr-code'}}},[_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.account.name)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.account.domain.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.account.title))])],1),_c(VListItemAction,[_c(VIcon,[_vm._v("fas fa-qrcode")])],1)],1):_vm._e(),_c(VList,{staticClass:"app-drawer-list"},_vm._l((_vm.permittedItems),function(item,idx){return _c(VListItem,{key:idx,class:[item.class, 'text-left'],attrs:{"link":Boolean(item.route),"to":item.route,"exact":""},on:{"click":function($event){(item.onclick && item.onclick()) || null}}},[_c(VListItemIcon,[(item.icon)?_c(VIcon,[_vm._v(_vm._s(item.icon))]):_vm._e(),(item.img)?_c(VImg,{staticClass:"app-drawer-list-icon",attrs:{"width":"32px","height":"32px","contain":"","src":item.img}}):_vm._e(),(item.icon || item.img)?_c('div',{staticClass:"active-avatar"},[(item.icon)?_c(VIcon,[_vm._v(_vm._s(item.icon))]):_vm._e(),(item.img)?_c(VImg,{class:['app-drawer-list-icon', {'no-grayscale': item.no_grayscale}],attrs:{"width":"40px","height":"40px","contain":"","src":item.img}}):_vm._e()],1):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" "),(item.admin)?_c(VChip,{attrs:{"x-small":"","label":""}},[_vm._v("Admin Only ")]):_vm._e()],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }