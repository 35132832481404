<template>
  <v-container class="login-wrapper" fluid>
    <v-container class="login">
      <v-row>
        <v-col>
          <v-img src="../assets/logo.png"
                 height="10vh"
                 max-height="200px"
                 contain></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block
                 color="#5bc0de"
                 dark
                 @click="$loginTeamNote()">
            {{ $t('login.login_with_teamnote') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!showLocalLogin">
        <v-col>
          <v-btn text
                 @click="showLocalLogin = true">Login with local account
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="showLocalLogin">
        <v-row no-gutters>
          <v-col>
            <v-text-field :label="$t('login.username')"
                          v-model="username"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field :label="$t('login.password')"
                          v-model="password"
                          type="password"
                          @keyup.enter="nextStep"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block
                   color="primary"
                   dark
                   @click="nextStep">
              {{ $t('login.title') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

    </v-container>
  </v-container>
</template>

<script>
import {watch, ref, onMounted} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";
import {DOMAIN_ID} from "@/constants";
import {Announcement} from "@/store/models";
import AnnouncementDialog from "@/components/AnnouncementDialog";

export default {
  name: 'Login',
  components: {},
  setup(props, {root}) {
    // onMounted(() => {
    //   root.$store.dispatch(ACTION_TYPES.HIDE_APPBAR);
    // });
    //
    // onUnmounted(() => {
    //   root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR);
    // })

    const afterLogin = ref(null);
    const showLocalLogin = ref(false);
    const username = ref('');
    const password = ref('');
    const nextStep = async function () {
      await root.$store.dispatch(ACTION_TYPES.LOGIN, {
        domain_id: DOMAIN_ID,
        username: username.value,
        password: password.value,
      });
      await root.$store.dispatch(ACTION_TYPES.GET_DATA);

      // Show announcements
      const announcements = Announcement.all();
      for (let i in announcements) {
        await root.$dialog.showAndWait(AnnouncementDialog, {
          showClose: false,
          content: announcements[i].content,
          width: '80vw',
          persistent: true,
          scrollable: true,
        })
      }
    };

    watch(() => root.$store.getters.account, (newValue) => {
      if (newValue) {
        if (afterLogin.value && afterLogin.value !== '/login/sso') {
          root.$router.replace({path: afterLogin.value});
        } else {
          root.$router.replace({name: 'select-type'});
        }
      }
    }, {immediate: true})

    onMounted(() => {
      afterLogin.value = root.$store.getters.afterLogin;
      if (root.$route.params.autoLogin === 'sso') {
          root.$loginTeamNote('/');
      }
    })

    return {
      showLocalLogin,
      username,
      password,
      nextStep,
    }
  }
}
</script>

<style lang="less">
.login-wrapper {
  height: 100%;
  background-color: white;
  background-image: url('../assets/background.png');
  background-size: cover;
  background-position: top;

  .login {
    max-width: 750px;

    .login-footer {
      font-size: 60%;
    }
  }
}

</style>
