import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title || _vm.$t('booking.edit_recur_confirm_msg'))+" ")]),_c(VCardText,[_c(VRadioGroup,{model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c(VRadio,{attrs:{"label":_vm.$t('booking.edit_recur_this'),"value":"this"}}),_c(VRadio,{attrs:{"label":_vm.$t('booking.edit_recur_following'),"value":"following"}})],1)],1),_c(VCardActions,{staticClass:"text-right"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit', _vm.edit)}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }