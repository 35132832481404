<template>
  <v-container class="pending-approval" fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <DatePickerInput range
                         show-from-today
                         v-model="dateRange"></DatePickerInput>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select outlined
                  clearable
                  hide-details
                  :label="$t('booking.item')"
                  :items="itemOptions"
                  v-model="filterItemId"></v-select>
      </v-col>
    </v-row>

    <RemotePaginatedList full-height
                         :headers="headers"
                         multi-sort
                         hide-default-footer
                         :items-per-page="-1"
                         data-url="client/pending_approval/"
                         data-key="bookings"
                         :extra-params="extraParams"
                         :extra-filters="extraFilters"
                         @click:row="onBookingClicked"
                         :refresh-trigger.sync="refreshTrigger">
      <template v-slot:item.created_at="{value}">
        {{ $parseDate(value) }}
      </template>
      <template v-slot:item.start_time="{item}">
        {{ $parseDate(item.start_time, 'yyyy-MM-dd HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
      </template>
      <template v-slot:item.item="{item}">
        {{ item.item.name }}
      </template>
    </RemotePaginatedList>
  </v-container>
</template>
<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import BGModal from "@/components/BGModal";
import RemotePaginatedList from "@/components/RemotePaginatedList";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from 'luxon';
import {Item, ItemApprover, ItemType} from "@/store/models";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'PendingApproval',
  components: {DatePickerInput, RemotePaginatedList, BGModal, BannerFrame},
  setup(props, {root}) {
    const itemType = computed(() => {
      if (root.$route.params.itemTypeId) {
        return ItemType.find(root.$route.params.itemTypeId);
      }
    });
    watch(() => itemType.value, (newValue) => {
      if (newValue) {
        root.$store.dispatch(ACTION_TYPES.SET_APP_BAR_TITLE, `${root.$t('app_drawer.pending_approval')} - ${newValue.name}`)
      }
    })

    const now = DateTime.fromJSDate(new Date())
    const dateRange = ref([
      now.toFormat('yyyy-MM-dd'),
      now.plus({days: 30}).toFormat('yyyy-MM-dd'),
    ]);

    const itemOptions = computed(() => {
      const account = root.$store.getters.account;
      const managedItemIds = ItemApprover.query().where('user_id', account.id).all().map((a) => a.item_id);
      let query = Item.query().whereIdIn(managedItemIds)
      if (root.$route.params.itemTypeId) {
        query = query.where('type_id', root.$route.params.itemTypeId)
      }
      return query.all().map((i) => {
        return {text: i.name, value: i.id};
      });
    })
    const filterItemId = ref(null);
    const extraParams = computed(() => {
      let params = {};

      if (dateRange.value && dateRange.value.length === 2) {
        params = {
          ...params,
          from_date: dateRange.value[0],
          to_date: dateRange.value[1],
        }
      }

      if (filterItemId.value) {
        params = {
          ...params,
          item_id: filterItemId.value,
        }
      }

      return params;
    })
    const extraFilters = computed(() => {
      let filters = {};

      if (root.$route.params.itemTypeId) {
        filters['item.type.id'] = root.$route.params.itemTypeId;
      }

      return filters;
    })

    const load = async function () {
      refreshTrigger.value = true;
    };

    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at', sortable: true},
      {text: root.$t('booking.account'), value: 'account.name', sortable: true},
      {text: root.$t('booking.item'), value: 'item.name', sortable: true},
      {text: root.$t('booking.date'), value: 'start_time', sortable: true},
      {text: root.$t('booking.title'), value: 'title', sortable: true},
    ]);
    const refreshTrigger = ref(false);

    const onBookingClicked = function (item) {
      root.$router.push({name: 'approve-booking', params: {bookingId: item.id}})
    };

    watch(() => root.$store.getters.account, async (newValue) => {
      if (newValue) {
        // await load();
      }
    }, {immediate: true})

    onMounted(() => {
      // const now = DateTime.fromJSDate(new Date())
      //
      // dateRange.value = [
      //   now.startOf('month').toFormat('yyyy-MM-dd'),
      //   now.endOf('month').toFormat('yyyy-MM-dd'),
      // ];
    })

    return {
      itemType,
      dateRange,
      itemOptions,
      filterItemId,
      extraParams,
      extraFilters,
      headers,
      refreshTrigger,
      onBookingClicked,
    }
  }
}
</script>

<style lang="less">
.pending-approval {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pending-approval-date-filter {
    display: flex;
    flex: 0 0 auto;
  }
}
</style>
