<template>
  <BannerFrame :label="$t('app_drawer.pending_approval')">
    <v-container class="booking-detail" v-if="booking">
      <v-row>
        <v-col>
          <v-text-field :label="$t('booking.account')"
                        readonly
                        hide-details
                        :value="booking.account.name"></v-text-field>
          <v-text-field :label="$t('booking.item')"
                        readonly
                        hide-details
                        :value="booking.item.name"></v-text-field>
          <v-text-field :label="$t('booking.date')"
                        readonly
                        hide-details
                        :value="$parseDate(booking.start_time, 'yyyy-MM-dd')"></v-text-field>
          <v-text-field :label="$t('booking.original_time')"
                        readonly
                        hide-details
                        :value="$parseDate(booking.editing_booking.start_time, 'HH:mm') + ' - ' + $parseDate(booking.editing_booking.end_time, 'HH:mm')"
                        v-if="booking.editing_booking"></v-text-field>
          <v-text-field :label="$t('booking.time')"
                        readonly
                        hide-details
                        :value="$parseDate(booking.start_time, 'HH:mm') + ' - ' + $parseDate(booking.end_time, 'HH:mm')"></v-text-field>
          <v-text-field :label="$t('booking.title')"
                        placeholder=" "
                        hide-details
                        v-model="booking.title"
                        v-if="booking.title">
          </v-text-field>
          <v-text-field :label="$t('booking.created_at')"
                        readonly
                        hide-details
                        :value="$parseDate(booking.created_at)"></v-text-field>
          <v-textarea :label="$t('booking.status')"
                      readonly
                      hide-details
                      :value="$t(`booking_record_status.${booking.status}`)"
                      rows="1"
                      auto-grow
                      no-resize></v-textarea>
        </v-col>
<!--        <v-col>-->

<!--        </v-col>-->
      </v-row>

      <v-row v-if="conflicts.length > 0">
        <v-col>
          <v-input hide-details
                   class="mb-8">
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light" style="font-size: 13px">Conflicted Bookings</legend>
              <v-data-table :headers="headers"
                            :items="conflicts"
                            :items-per-page="-1"
                            hide-default-footer
                            mobile-breakpoint="0">
                <template v-slot:item.created_at="{value}">
                  {{ $parseDate(value) }}
                </template>
                <template v-slot:item.start_time="{value}">
                  {{ $parseDate(value, 'yyyy-MM-dd') }}
                </template>
                <template v-slot:item.time="{item}">
                  {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
                </template>
                <template v-slot:item.status="{value}">
                  {{ $t(`booking_status.${value}`) }}
                </template>
                <template #item.chairs="{item}">
                  <div v-for="i in eventChairs(item)"
                       :key="i.id">
                    {{ i.account.name }}
                  </div>
                </template>
                <template #item.detail="{item}">
                  <v-btn icon
                         @click="showBooking(item)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-input>
        </v-col>
      </v-row>

      <v-footer app
                inset
                class="pr-6 py-3">
        <v-row no-gutters>
          <v-btn color="primary"
                 @click="$router.back()">
            {{ $t('back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 @click="submit(false)">
            {{ $t('booking.reject') }}
          </v-btn>
          <v-btn class="ml-4"
                 color="primary"
                 @click="submit(true)">
            {{ $t('booking.approve') }}
          </v-btn>
        </v-row>
      </v-footer>
    </v-container>

    <BookingDetailModal :shown="bookingDetailModalShown"
                        @dismiss="bookingDetailModalShown = false"
                        :booking="showingBooking"></BookingDetailModal>
  </BannerFrame>
</template>
<script>
import _ from 'lodash';
import {onMounted, ref} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {ACTION_TYPES} from "@/store/types";
import {BOOKING_RECORD_STATUS, INVITATION_ROLE} from "@/constants";
import BookingDetailModal from "@/components/BookingDetailModal";

export default {
  name: 'ApproveBooking',
  components: {BookingDetailModal, BannerFrame},
  setup(props, {root}) {
    const booking = ref(null);
    const getBooking = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/pending_approval/',
        params: {booking_id: root.$route.params.bookingId},
      });

      if (response.body.success) {
        booking.value = response.body.booking;

        await getConflicts()
      }
    }


    const conflicts = ref([]);
    const getConflicts = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/pending_approval/conflicts/',
        params: {booking_id: root.$route.params.bookingId},
      });

      if (response.body.success) {
        conflicts.value = response.body.bookings;
      }
    }
    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at'},
      {text: root.$t('booking.account'), value: 'account.name'},
      {text: root.$t('booking.item'), value: 'item.name'},
      {text: root.$t('booking.date'), value: 'start_time'},
      {text: root.$t('booking.time'), value: 'time', sortable: false},
      {text: root.$t('booking.title'), value: 'title'},
      {text: root.$t('booking.chairs'), value: 'chairs'},
      {text: '', value: 'detail', width: '50px'},
      // {text: root.$t('booking.status'), value: 'status'},
      // {text: root.$t('booking.approved'), value: 'approve'},
    ]);

    const eventChairs = function (event) {
      return _.filter(event.invitations, (i) => i.role === INVITATION_ROLE.CHAIR);
    }

    const bookingDetailModalShown = ref(false);
    const showingBooking = ref(null);
    const showBooking = function (booking) {
      showingBooking.value = booking;
      bookingDetailModalShown.value = true;
    }

    const submit = async function (approve) {
      const confirmMessage = approve ? root.$t('booking.confirm_approve_msg') : root.$t('booking.confirm_disapprove_msg');
      const successMessage = approve ? root.$t('booking.approve_success_msg') : root.$t('booking.disapprove_success_msg');
      if (await root.$dialog.confirm({text: confirmMessage, showClose: false})) {
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/pending_approval/approve/',
          params: {
            booking_id: root.$route.params.bookingId,
            approve,
          }
        })

        if (response.body.success) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, successMessage);
          root.$router.replace({name: 'pending-approval'})
        }
      }
    };

    onMounted(() => {
      getBooking();
    })

    return {
      booking,

      conflicts,
      headers,

      eventChairs,

      bookingDetailModalShown,
      showingBooking,
      showBooking,

      BOOKING_RECORD_STATUS,

      submit,
    }
  },
}
</script>

<style lang="less">
.booking-detail {
  background-color: white;
  padding: 2rem;

  .v-label {
    color: var(--v-primary-base);
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    display: none;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    display: none;
  }

}
</style>
