import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","content-class":"pending-bookings"},model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('booking_status.PENDING_APPROVAL')))]),_c(VCardText,[_c(VRow,[_c(VCol,[(_vm.session)?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.session.pending_bookings,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value, 'yyyy-MM-dd'))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("booking_status." + value)))+" ")]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.showBooking(item)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]}}],null,false,934404838)}):_vm._e()],1)],1)],1),_c('BookingDetailModal',{attrs:{"shown":_vm.bookingDetailModalShown,"booking":_vm.showingBooking},on:{"dismiss":function($event){_vm.bookingDetailModalShown = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }