import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pending-approval",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('DatePickerInput',{attrs:{"range":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1),_c('RemotePaginatedList',_vm._b({attrs:{"full-height":"","headers":_vm.headers,"multi-sort":"","data-url":"client/bookings/","data-key":"bookings","extra-params":_vm.extraParams,"refresh-trigger":_vm.refreshTrigger,"mobile-breakpoint":"0"},on:{"click:row":_vm.onBookingClicked,"update:refreshTrigger":function($event){_vm.refreshTrigger=$event},"update:refresh-trigger":function($event){_vm.refreshTrigger=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value, 'yyyy-MM-dd'))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("booking_status." + value)))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingOwnedByMe(item) ? 'Owner' : 'Host')+" ")]}}])},'RemotePaginatedList',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }