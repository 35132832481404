import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"outlined":"","hide-details":"","label":_vm.label,"items":_vm.accounts,"return-object":"","item-text":_vm.accountText,"filter":_vm.filter},on:{"input":_vm.onAccountSelect},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.name))}}),(item.title)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.title))}}):_vm._e(),(item.mobile)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.mobile))}}):_vm._e(),(item.email)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.email))}}):_vm._e()],1)]}}]),model:{value:(_vm.selectedAccount),callback:function ($$v) {_vm.selectedAccount=$$v},expression:"selectedAccount"}})}
var staticRenderFns = []

export { render, staticRenderFns }