import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:['user-selector', 'pa-0', {'full-height': _vm.fullHeight}]},[_c(VRow,{staticClass:"flex-grow-0"},[_c(VCol,[_c('AccountAutoComplete',{attrs:{"label":_vm.label,"account-text":_vm.getAccountText,"account-filter":_vm.accountFilter},on:{"input":_vm.onAccountSelect}})],1)],1),_c(VRow,{staticClass:"flex-grow-1 overflow-y-auto"},[_c(VCol,[_c(VList,{staticClass:"selected-account-list text-left",attrs:{"two-line":""}},_vm._l((_vm.selectedAccounts),function(account,index){return _c(VListItem,{key:account.id},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(" fas fa-user ")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(account.name)}}),(account.title)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(account.title)+" ")]):_vm._e(),(account.mobile)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(account.mobile)+" ")]):_vm._e(),(account.email)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(account.email)+" ")]):_vm._e()],1),(_vm.hideDelete.length === 0 || _vm.hideDelete.indexOf(account.id) === -1)?_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.removeSelectedAccount(index)}}},[_c(VIcon,[_vm._v(" fas fa-times ")])],1)],1):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }