import {ACTION_TYPES} from "@/store/types";
import {v4 as uuid} from "uuid";
import i18n from '@/plugins/i18n';
import _ from 'lodash';
import {
    Announcement,
    Booking,
    BookingRecord,
    Invitation,
    Item,
    ItemApprover,
    ItemType, ItemTypeAdditionalItem, ItemTypeAttributes,
    Location,
    PriceTier,
    Region,
    Session,
    User
} from "@/store/models";

export const apiModule = {
    state: {
        baseUrl: '/api/',
        requests: {},
        loadingMessage: null,

        userLoadTimestamp: null,

        roomBookingSettings: null,
    },
    mutations: {
        startRequest(state, {uid, record}) {
            state.requests = {
                ...state.requests,
                [uid]: record,
            };
        },
        endRequest(state, uid) {
            let d = state.requests;
            delete d[uid];
            state.requests = {...d};
        },
        clearRequest(state) {
            state.requests = {};
        },
        setLoadingMessage(state, msg) {
            state.loadingMessage = msg;
        },

        setUserLoadTimestamp(state) {
            state.userLoadTimestamp = (new Date()).getTime();
        },

        setRoomBookingSettings(state, settings) {
            state.roomBookingSettings = settings;
        },
    },
    actions: {
        [ACTION_TYPES.START_LOADING]: {
            root: true,
            handler({commit}, {uid, record, timeout}) {
                commit('startRequest', {uid, record});

                if (timeout !== -1) {
                    setTimeout(() => {
                        commit('endRequest', uid);
                    }, timeout || 30000);
                }
            }
        },
        [ACTION_TYPES.STOP_LOADING]: {
            root: true,
            handler({commit}, uid) {
                commit('endRequest', uid);
            }
        },
        [ACTION_TYPES.CLEAR_LOADING]: {
            root: true,
            handler({commit}) {
                commit('clearRequest');
            }
        },
        [ACTION_TYPES.SET_LOADING_MSG]: {
            root: true,
            handler({commit}, msg) {
                commit('setLoadingMessage', msg);
            }
        },
        [ACTION_TYPES.CALL_API]: {
            root: true,
            async handler({state, commit, dispatch}, {url, params, opt}) {
                const {not_blocking, throw_error, disable_error_msg, extra} = {
                    not_blocking: false,
                    throw_error: false,
                    disable_error_msg: false,
                    extra: null,
                    ...opt,
                };
                const uid = uuid();

                if (!not_blocking) {
                    dispatch(ACTION_TYPES.START_LOADING, {
                        uid, record: {url, params},
                    });
                }

                try {
                    const response = await this._vm.$http.post(state.baseUrl + url, params, extra);
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }
                    return response;
                } catch (response) {
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }

                    if (!disable_error_msg) {
                        if (typeof response.body === 'object') {
                            if ('msg' in response.body) {
                                let msg = response.body.msg;
                                if (i18n.te('error_messages.' + msg)) {
                                    dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t(`error_messages.${msg}`));
                                } else {
                                    dispatch(ACTION_TYPES.SHOW_SNACKBAR, msg);
                                }
                            }
                            // dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t('error_messages.default'));
                        } else if (response.status !== 401 && response.status !== 302) {
                            dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t('error_messages.default'));
                        }
                    }

                    if (throw_error) {
                        throw response;
                    } else if (response.status === 401 || response.status === 302) {
                        dispatch(ACTION_TYPES.LOGOUT);
                    }
                }
            },
        },

        // Data
        [ACTION_TYPES.GET_DATA]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/data/',
                    params,
                });

                await ItemTypeAttributes.deleteAll();
                await ItemTypeAdditionalItem.deleteAll();
                await PriceTier.deleteAll();

                await Announcement.create({
                    data: response.body.announcements || [],
                })

                await Location.create({
                    data: response.body.locations
                });
                await Region.create({
                    data: response.body.regions
                });
                await Item.create({
                    data: response.body.items
                });
                await ItemType.create({
                    data: response.body.item_types
                });

                await ItemApprover.deleteAll();
                if (response.body.item_approvers) {
                    _.forIn(response.body.item_approvers, async (users, item_id) => {
                        if (users.length > 0) {
                            await User.insert({data: users});
                            await ItemApprover.insert({
                                data: users.map((u) => {
                                    return {item_id, user_id: u.id}
                                })
                            })
                        }
                    })
                }

                commit('setRoomBookingSettings', response.body.room_booking_settings);

                return response;
            },
        },

        // Search
        [ACTION_TYPES.SEARCH_SESSIONS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/search/',
                    params,
                });

                await Item.create({
                    data: response.body.items,
                });
                await PriceTier.create({
                    data: response.body.price_tiers,
                });
                await Session.create({
                    data: response.body.sessions,
                });

                return response;
            },
        },

        // Booking
        [ACTION_TYPES.BOOK_SESSIONS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/book/',
                    params,
                });
            },
        },
        [ACTION_TYPES.BOOK_RECURRING_SESSIONS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/book_recurring/',
                    params,
                });
            },
        },
        [ACTION_TYPES.GET_BOOKING_RECORDS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/booking_records/',
                    params,
                });

                if (response.body.booking_record) {
                    await BookingRecord.insert({
                        data: response.body.booking_record,
                    })
                }

                if (response.body.booking_records) {
                    await BookingRecord.insert({
                        data: response.body.booking_records,
                    })
                }

                return response;
            },
        },
        [ACTION_TYPES.GET_BOOKINGS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/bookings/',
                    params,
                });

                if (response.body.bookings) {
                    // await Booking.deleteAll();

                    await Booking.insert({
                        data: response.body.bookings
                    });

                    await BookingRecord.insert({
                        data: response.body.booking_records,
                    })

                    if (response.body.invitations) {
                        await Invitation.insert({
                            data: response.body.invitations,
                        })
                    }

                    // await Item.insert({
                    //     data: response.body.items
                    // })

                    // return response.body.bookings;
                }

                return response;
            },
        },
        [ACTION_TYPES.CANCEL_BOOKING]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/cancel/',
                    params,
                });

                if (response.body.booking_records) {
                    await BookingRecord.insert({
                        data: response.body.booking_records
                    });

                    await Item.insert({
                        data: response.body.items
                    })

                    return response.body.booking_records;
                }
            },
        },
        [ACTION_TYPES.SEND_ICAL]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/send_ical/',
                    params,
                });
            },
        },


        [ACTION_TYPES.SAVE_BOOKING_TITLE]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/bookings/save_title/',
                    params,
                    opt: {throw_error: true}
                });
            },
        },
        [ACTION_TYPES.CHECK_IN_BOOKING]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/bookings/check_in/',
                    params,
                    opt: {throw_error: true}
                });
            },
        },
        [ACTION_TYPES.CHECK_OUT_BOOKING]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/bookings/check_out/',
                    params,
                    opt: {throw_error: true}
                });
            },
        },


        [ACTION_TYPES.GET_PENDING_APPROVALS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/approvals/',
                    params,
                });

                if (response.body.bookings) {
                    await User.insert({
                        data: response.body.accounts
                    });

                    await Item.insert({
                        data: response.body.items
                    });

                    await Booking.create({
                        data: response.body.bookings
                    });


                    return response.body.bookings;
                }
            },
        },
        [ACTION_TYPES.APPROVE_BOOKING]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/approve/',
                    params,
                });
            },
        },

        // Schedule
        [ACTION_TYPES.GET_SCHEDULE]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/schedule/',
                    params,
                });

                if (response.body.bookings) {
                    await Booking.deleteAll();

                    await Booking.insert({
                        data: response.body.bookings
                    });

                    await BookingRecord.insert({
                        data: response.body.booking_records,
                    })

                    if (response.body.invitations) {
                        await Invitation.insert({
                            data: response.body.invitations,
                        })
                    }
                }

                return response;
            },
        },
        [ACTION_TYPES.RSVP_INVITATION]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'rsvp/submit/',
                    params,
                });

                if (response.body.invitations) {
                    await Invitation.insert({
                        data: response.body.invitations,
                    })
                }

                return response;
            },
        },

        // Users
        [ACTION_TYPES.GET_USERS]: {
            root: true,
            async handler({state, dispatch, commit}, {params, forceReload, not_blocking}) {
                if (!forceReload && state.userLoadTimestamp && ((new Date()).getTime() - state.userLoadTimestamp) < 24 * 3600 * 1000) {
                    return;
                }

                const response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/accounts/',
                    params,
                    opt: {
                        not_blocking
                    }
                });


                if (response.body.users) {
                    await User.deleteAll();

                    await User.insert({
                        data: response.body.users,
                    });

                    commit('setUserLoadTimestamp');
                }

                return response;
            },
        },

        [ACTION_TYPES.SET_ITEM_SESSION_GENERATION_LIMIT]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/managed_items/set_session_generation_limit/',
                    params,
                });
            },
        },

        // Event
        [ACTION_TYPES.GET_EVENT]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/event/',
                    params,
                });
            },
        },
        [ACTION_TYPES.CREATE_EVENT]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/event/create/',
                    params,
                });
            },
        },
        [ACTION_TYPES.LINK_EVENT_BOOKING]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/event/link_booking/',
                    params,
                });
            },
        },
        [ACTION_TYPES.UNLINK_EVENT_BOOKING]: {
            root: true,
            async handler({dispatch, commit}, params) {
                return await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/event/unlink_booking/',
                    params,
                });
            },
        },

        // Dashboard
        [ACTION_TYPES.GET_SESSIONS]: {
            root: true,
            async handler({dispatch, commit}, params) {
                let response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/session/',
                    params,
                });

                if (response.body.sessions) {
                    await Session.create({
                        data: response.body.sessions
                    });
                }

                if (response.body.items) {
                    await Item.insert({
                        data: response.body.items
                    });
                }

                return response;
            },
        },

    },
    getters: {
        requests(state) {
            return state.requests;
        },
        requestCount(state) {
            return Object.keys(state.requests).length;
        },
        loadingMessage(state) {
            return state.loadingMessage;
        },
        roomBookingSettings(state) {
            return state.roomBookingSettings;
        },
    },
}
