<template>
  <v-navigation-drawer color="#FFFFFF"
                       v-model="drawerShown"
                       app
                       width="320"
                       :right="right"
                       touchless
                       id="appDrawer">
    <v-container fluid class="app-drawer-container">
      <v-list-item class="account-info"
                   link
                   :to="{name: 'scan-qr-code'}"
                   v-if="account">
        <v-list-item-content class="text-left">
          <v-list-item-title class="title">
            {{ account.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ account.domain.name }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ account.title }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-icon>fas fa-qrcode</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list class="app-drawer-list">
        <v-list-item v-for="(item, idx) in permittedItems"
                     :key="idx"
                     :link="Boolean(item.route)"
                     :to="item.route"
                     :class="[item.class, 'text-left']"
                     @click="(item.onclick && item.onclick()) || null"
                     exact>
          <v-list-item-icon>
            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            <v-img v-if="item.img"
                   width="32px"
                   height="32px"
                   contain
                   class="app-drawer-list-icon"
                   :src="item.img"></v-img>
            <div class="active-avatar" v-if="item.icon || item.img">
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <v-img v-if="item.img"
                     width="40px"
                     height="40px"
                     contain
                     :class="['app-drawer-list-icon', {'no-grayscale': item.no_grayscale}]"
                     :src="item.img"></v-img>
            </div>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
              <v-chip x-small
                      label
                      v-if="item.admin">Admin Only
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import {computed, ref, watch} from '@vue/composition-api'
import {USER_TYPE} from "@/constants";
import {ACTION_TYPES} from "@/store/types";
import {Item, ItemApprover, ItemType} from "@/store/models";

export default {
  name: 'AppDrawer',
  props: {
    value: Boolean,
    right: Boolean,
  },
  setup(props, {emit, root}) {
    // Drawer Open State
    const drawerShown = ref(Boolean(props.value));
    watch(drawerShown, (newValue, oldValue) => {
      if (newValue != null && newValue !== oldValue) {
        emit('input', newValue);
      }
    });

    // Props
    watch(() => props.value, (newValue, oldValue) => {
      if (newValue != null && newValue !== oldValue) {
        drawerShown.value = newValue;
      }
    });

    //
    const logout = async function () {
      if (await root.$dialog.confirm({text: root.$t('app_drawer.logout_confirm_msg')})) {
        root.$store.dispatch(ACTION_TYPES.LOGOUT);
      }
    };

    const backToTeamNote = function () {
      window.tnConnector.ui.openSideMenu({}, () => {
      }, () => {
      });
    }

    const account = computed(() => {
      return root.$store.getters.account;
    })

    // Menu Items
    const items = computed(() => {
      const account_group_ids = root.$store.getters.account.account_groups;

      let managedItemIds = ItemApprover.query().where('user_id', account.value.id).get().map((a) => a.item_id);
      let managedItemCount = managedItemIds.length;
      let managedItemTypeIds = _.uniq(Item.query().whereIdIn(managedItemIds).get().map(i => i.type_id));
      let managedItemTypes = ItemType.query().whereIdIn(managedItemTypeIds).orderBy('ordering').get();
      return [
        ...(root.$isTeamNote() ? [
          {
            title: root.$t('app_drawer.teamnote'),
            img: require('@/assets/teamnote.png'),
            no_grayscale: true,
            onclick: backToTeamNote
          }
        ] : []),

        // {title: root.$t('app_drawer.dashboard'), icon: 'fas fa-eye', route: {name: 'dashboard'}},
        {title: root.$t('app_drawer.book_facilities'), icon: 'fas fa-calendar-plus', route: {name: 'select-type'}},
        ...ItemType.query().where((i) => !i.deleted).where((i) => i.open_for_booking).where((i)=> {
          return !i.open_for_booking_groups || i.open_for_booking_groups.length === 0 || _.intersection(i.open_for_booking_groups, account_group_ids).length !== 0;
        }).orderBy('ordering').get().map((t) => {
          return {title: t.name, route: {name: 'search', params: {itemTypeId: t.id}}, class: 'font-weight-light'}
        }),

        {title: root.$t('app_drawer.schedule'), icon: 'fas fa-calendar-alt'},
        {title: root.$t('app_drawer.my_schedule'), route: {name: 'schedule'}, class: 'font-weight-light'},
        {title: root.$t('app_drawer.item_schedule'), route: {name: 'item-schedule'}, class: 'font-weight-light'},

        {title: root.$t('app_drawer.my_bookings'), icon: 'mdi-playlist-check', route: {name: 'my-bookings'}},

        ...(managedItemCount > 0 ? [
          {
            title: root.$t('app_drawer.pending_approval'),
            icon: 'fas fa-calendar-check',
            admin: true,
          },
          ...(managedItemTypes.map((t) => {
            return {
              title: t.name,
              route: {name: 'pending-approval', params: {itemTypeId: t.id}},
              class: 'font-weight-light'
            }
          })),
          {
            title: root.$t('app_drawer.managed_items'),
            icon: 'fas fa-tasks',
            route: {name: 'managed-items'},
            admin: true
          },
        ] : []),

        {
          title: root.$t('app_drawer.register_device'),
          icon: 'fas fa-tablet-alt',
          route: {name: 'register-device'},
          admin: true,
          user_type: [USER_TYPE.SUPER_ADMIN, USER_TYPE.ADMIN],
        },

        ...(root.$isTeamNote() ? [] : [
          {title: root.$t('app_drawer.logout'), icon: 'fas fa-sign-out-alt', onclick: logout}
        ]),
      ]
    });
    const permittedItems = computed(() => {
      return _.filter(items.value, (item) => {
        return !item.user_type || account.value && item.user_type.indexOf(account.value.type) !== -1;
      })
    })

    // On Menu Click
    const onMenuItemClick = (item) => {
      drawerShown.value = false;
      root.$router.push(item.route);
    };

    return {
      drawerShown,
      logout,
      account,
      permittedItems,
      items,
      onMenuItemClick,
    }
  },
  watch: {
    value(newValue) {
      this.drawerShown = newValue;
    },
    drawerShown(newValue) {
      this.$emit('input', newValue);
    },
  },
}
</script>

<style lang="less">
#appDrawer {
  .app-drawer-container {
    padding: 0;
    height: 100% !important;
    display: flex;
    flex-direction: column;

    .account-info {
      font-weight: bold;
      font-size: 110%;
      padding: 12px;
      flex: 0 0 auto;
    }

    .app-drawer-list-icon {
      filter: grayscale(100%);

      &.no-grayscale {
        filter: grayscale(0%) !important;
      }
    }

    .app-drawer-list {
      flex: 1 1 0;
      overflow: auto;

      .v-list-item__icon {
        position: relative;

        .active-avatar {
          display: none;
          position: absolute;
          height: 250%;
          width: 250%;
          background-color: white;
          top: -75%;
          left: -75%;
          border-radius: 100%;
          border: var(--v-primary-base) 3px solid;

          .v-icon, .v-image {
            left: 25%;
            top: 25%;
            height: 50%;
            width: 50%;
          }

          &::before {
            content: '';
            background-color: var(--v-primary-base);
            width: 200%;
            left: -150%;
            height: 100%;
            position: absolute;
            z-index: -1;
          }
        }
      }

      .v-list-item--active {
        //border-left: solid 4px var(--v-primary-base);
        //color: #998065;
        color: var(--v-primary-base);

        .app-drawer-list-icon {
          filter: grayscale(0%) !important;
        }

        .v-list-item__icon {
          .active-avatar {
            display: block;
          }
        }
      }
    }
  }
}
</style>
