<template>
  <v-app id="app">
    <AppDrawer v-model="drawerShown"
               v-if="account"></AppDrawer>
    <v-app-bar app
               color="primary"
               dark
               id="appBar"
               :hide-on-scroll="$vuetify.breakpoint.mdAndDown"
               scroll-threshold="56"
               v-model="appBarShown">
      <v-app-bar-nav-icon v-if="account && $vuetify.breakpoint.mdAndDown"
                          @click.stop="drawerShown = !drawerShown"/>
      <div class="app-bar-title">{{ appBarTitle }}</div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackBarShown"
                :timeout="3000">
      {{ snackBarContent }}
    </v-snackbar>

    <v-overlay :value="isLoading"
               z-index="9999">
      <GridLoader></GridLoader>
    </v-overlay>
  </v-app>
</template>

<script>
import AppDrawer from "./components/AppDrawer";
import GridLoader from 'vue-spinner/src/GridLoader';
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import {DOMAIN_ID} from "@/constants";
import {Announcement} from "@/store/models";
import AnnouncementDialog from "@/components/AnnouncementDialog";

export default {
  name: 'App',
  components: {AppDrawer, GridLoader},
  setup(props, {root}) {
    root.$store.dispatch(ACTION_TYPES.IS_LOGGING_IN, false);
    // App Bar
    // const appBarShown = ref(!root.$isTeamNote());
    const appBarShown = ref(true);
    watch(() => appBarShown.value, (newValue) => {
      root.$store.dispatch(ACTION_TYPES.SET_APP_BAR_SHOWN, newValue);
    }, {immediate: true});
    const appBarTitle = computed(() => root.$store.getters.appBarTitle);

    // Snack Bar
    const snackBarShown = ref(false);
    const snackBarContent = computed(() => root.$store.getters.snackBarContent);

    watch(() => root.$store.getters.snackBarContent, (content, oldContent) => {
      if (content) {
        snackBarShown.value = true;
      }
    }, {immediate: true});

    watch(() => snackBarShown.value, (shown, oldShown) => {
      if (oldShown && !shown) {
        root.$store.dispatch(ACTION_TYPES.HIDE_SNACKBAR);
      }
    }, {immediate: true});

    // Loading Spinner
    const isLoading = computed(() => {
      return root.$store.getters.requestCount !== 0;
    });

    // Login
    const account = computed(() => {
      return root.$store.getters.account;
    });
    watch(() => account.value, (newValue, oldValue) => {
      if (oldValue && !newValue) {
        console.log('LOGGED OUT');
        root.$router.replace({name: 'login'})
      }

      if (newValue && !oldValue) {
        console.log('LOGGED IN');
      }
    });
    const logout = function () {
      root.$store.dispatch(ACTION_TYPES.LOGOUT);
    };

    onMounted(async () => {
      root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);

      // Check if id_token is present
      const urlParams = new URLSearchParams(window.location.search);
      const id_token = urlParams.get('id_token');

      if (id_token) {
        await root.$store.dispatch(ACTION_TYPES.AUTHENTICATE_TEAMNOTE_JWT, {jwt: id_token, domain: DOMAIN_ID});
        history.replaceState(null, '', window.location.pathname + window.location.hash)
      }

      try {
        await root.$store.dispatch(ACTION_TYPES.GET_ME);
        await root.$store.dispatch(ACTION_TYPES.GET_DATA);
        await root.$store.dispatch(ACTION_TYPES.GET_USERS, {forceReload: true, not_blocking: true});
        await root.$store.dispatch(ACTION_TYPES.CLEAR_AFTER_LOGIN);

        // Show announcements
        const announcements = Announcement.all();
        for (let i in announcements) {
          await root.$dialog.showAndWait(AnnouncementDialog, {
            showClose: false,
            content: announcements[i].content,
            width: '80vw',
            persistent: true,
            scrollable: true,
          })
        }
      } catch (response) {
        await root.$store.dispatch(ACTION_TYPES.SET_AFTER_LOGIN, root.$router.currentRoute.fullPath);
        // Check if it is TeamNote WebView
        if (root.$isTeamNote()) {
          root.$loginTeamNote(root.$router.currentRoute.fullPath);
        } else {
          root.$router.replace({name: 'login'});
        }
      }
    });

    return {
      account,

      drawerShown: ref(!root.$vuetify.breakpoint.mobile),

      appBarShown,
      appBarTitle,

      snackBarShown,
      snackBarContent,

      isLoading,

      isTeamNote: root.$isTeamNote(),
    }
  },
};
</script>

<style lang="less">
html {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 100%;
  overscroll-behavior: none;
}

body {
  max-height: 100%;

  div {
    //overflow: auto;
    //-webkit-overflow-scrolling: touch;
  }
}

#app {
  --selected: #84BC39;
  --available: #B0DE72;
  --occupied: #ff7e19;
  --unavailable: #c6c6c6;
  --need-approval-selected: #E0CB31;
  --need-approval: #F7E667;

  font-family: Roboto, "PingFang TC", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-left: 0;
  padding-right: 0;
  height: 100%;

  .v-main {
    background-color: #efefef;
  }
}

#appBar {
  z-index: 5;

  .v-toolbar__content {
    //border-bottom: solid 1px #f2f2f2;
  }

  .app-bar-user-info {
    width: 275px;
  }

  .app-bar-title {
    font-weight: bold;
    //position: absolute;
    //width: 100%;
    //height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 120%;
    z-index: -1;
  }
}

.v-btn {
  &.secondary {
    .v-btn__content {
      color: black;
    }
  }
}

.v-data-table-header {
  th {
    background-color: #f4f5f7;
  }
}

.h-100 {
  height: 100%;
}

.mdi-account-star {
  transform: scaleX(-1);
}
</style>
