import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../assets/fontawesome.all.min';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export const PRIMARY_COLOR = '#998065';

export default new Vuetify({
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                // primary: '#ed1f29',
                primary: PRIMARY_COLOR,
                secondary: '#dfdfdf',
                accent: '#998065',
                error: '#ff5252',
            },
        },
    },
});
