<template>
  <v-menu ref="menu"
          v-model="showDatePicker"
          :close-on-content-click="false"
          :return-value="date"
          transition="scale-transition"
          offset-y
          min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field outlined
                    :dense="dense"
                    :append-icon="readonly ? null: 'fas fa-calendar-day'"
                    :label="label"
                    :placeholder="label"
                    v-model="dateString"
                    readonly
                    :rules="rules"
                    hide-details="auto"
                    v-on="readonly ? null : on"></v-text-field>
    </template>
    <v-date-picker v-model="date"
                   :range="range"
                   :min="min"
                   :max="max"
                   no-title
                   scrollable>
      <v-btn outlined text color="primary" @click="date = fromToday"
             v-if="range && showFromToday">From Today
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="clearDate">Clear</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import {DateTime} from "luxon";

export default {
  name: 'DatePickerInput',
  props: {
    value: [String, Array],
    label: String,
    range: Boolean,
    readonly: Boolean,
    dense: Boolean,
    min: String,
    max: String,
    rules: Array,
    showFromToday: Boolean,
  },
  setup(props, {root, emit}) {
    const fromToday = computed(() => {
      let today = DateTime.fromJSDate(new Date());
      return [
        today.toFormat('yyyy-MM-dd'),
        today.plus({years: 10}).toFormat('yyyy-MM-dd'),
      ];
    })
    const date = ref(props.value || (props.range ? [] : null));
    const dateString = computed(() => {
      return props.range ? (date.value.length === 2 ? `${date.value[0]} - ${date.value[1]}` : '') : date.value;
    })
    const clearDate = function () {
      date.value = (props.range ? [] : null);
      showDatePicker.value = false;
    };
    watch(() => props.value, (newVal) => {
      date.value = newVal;
    })
    watch(
        date,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            emit('input', newVal)
          }
        }
    );

    const showDatePicker = ref(false);

    return {
      fromToday,
      date,
      dateString,
      showDatePicker,
      clearDate,
    }
  }
}
</script>
