<template>
  <v-dialog v-model="dialogShown"
            scrollable
            content-class="schedule-booking-detail"
            max-width="480px">
    <v-card class="text-left"
            v-if="booking">
      <v-card-title :class="['schedule-booking-detail-title', booking.status]">{{
          booking.item.type.name
        }}
        <v-spacer></v-spacer>
        <v-tooltip top
                   v-if="shouldShowCancel">
          <template #activator="{on, attrs}">
            <v-btn icon
                   v-on="on"
                   v-bind="attrs"
                   dark
                   @click="cancelBooking">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Cancel Booking</span>
        </v-tooltip>
        <v-tooltip top
                   v-if="shouldShowEditAdditionalItem">
          <template #activator="{on, attrs}">
            <v-btn icon
                   v-on="on"
                   v-bind="attrs"
                   dark
                   @click="editAdditionalItem">
              <v-icon>mdi-pencil-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit Booking Details</span>
        </v-tooltip>
        <v-tooltip top
                   v-if="!hideEdit && booking.account.id === account.id">
          <template #activator="{on, attrs}">
            <v-btn icon
                   v-on="on"
                   v-bind="attrs"
                   dark
                   @click="editBooking">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit Booking</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-list dense
                v-if="!itemTypeLayoutDefinitions.length">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h3>{{ booking.title }}</h3>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>{{ $parseDate(booking.start_time) }} - {{
                    $parseDate(booking.end_time, 'HH:mm')
                  }}
                </div>
                <div v-if="booking.bookingRecord && booking.bookingRecord.recurrence">
                  {{
                    $parseCronString(booking.bookingRecord.recurrence, booking.bookingRecord.recur_until)
                  }}
                </div>
                <div>Booked On {{ $parseDate(booking.created_at) }}</div>
                <div v-if="booking.approved_by">Approved By {{ booking.approved_by.name }} On
                  {{ $parseDate(booking.approved_at) }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`booking_status.${booking.status}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(host, index) in booking.hosts">
            <v-list-item-icon>
              <v-icon>{{ index === 0 ? 'mdi-account-edit' : null }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ host.name }}
                <small v-if="host.id === booking.account.id"
                       class="text--disabled">(Creator)</small>
              </v-list-item-title>
              <v-list-item-subtitle v-if="host.title">
                {{ host.title }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="host.mobile">
                {{ host.mobile }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="host.email">
                {{ host.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-map-marker-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ booking.item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--Invitations-->
          <v-list-item v-if="bookingInvitations.length">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ bookingInvitations.length }}
                {{ bookingInvitations.length > 1 ? $t('invitation.counts') : $t('invitation.count') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ bookingInvitationDescriptions }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="invitation in bookingInvitations"
                       class="pl-8">
            <v-list-item-icon class="ml-2"
                              :key="invitation.id+invitationIcon(invitation)">
              <v-icon>{{ invitationIcon(invitation) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ invitation.account.name }}
                <small v-if="invitation.role === INVITATION_ROLE.CHAIR"
                       class="text--disabled">(Chair)</small></v-list-item-title>
              <!--                <v-list-item-subtitle v-text="invitation.account.username"></v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="invitation.account.title">
                {{ invitation.account.title }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="invitation.account.mobile">
                {{ invitation.account.mobile }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="invitation.account.email">
                {{ invitation.account.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!--Additional Items-->
          <v-list-item v-for="additionalItem in bookingAdditionalItems">
            <v-list-item-icon>

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{ additionalItem.item_type_additional_item.name }}</v-list-item-subtitle>
              <v-list-item-title>{{ additionalItem.value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense v-if="filteredItems.length">
          <v-list-item v-for="item in filteredItems">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ item.label }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <!-- Display / Text -->
                <span v-if="[
                    ITEM_TYPE_LAYOUT_TYPES.DISPLAY,
                    ITEM_TYPE_LAYOUT_TYPES.TEXT,
                ].indexOf(item.type) !== -1">{{ getDisplayValue(item) }}</span>
                <!-- Datetime / Date / Time Display -->
                <span v-if="[
                    ITEM_TYPE_LAYOUT_TYPES.DATETIME_DISPLAY,
                    ITEM_TYPE_LAYOUT_TYPES.DATE_DISPLAY,
                    ITEM_TYPE_LAYOUT_TYPES.TIME_DISPLAY,
                ].indexOf(item.type) !== -1">{{ $parseDate(getDisplayValue(item), item.format) }}</span>
                <!-- BOOKING DATETIME-->
                <span v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.BOOKING_DATETIME">
                  {{
                    `${$parseDate(booking.start_time, 'yyyy-MM-dd HH:mm')} - ${$parseDate(booking.end_time, 'HH:mm')}`
                  }}
                </span>
                <!-- Recurring -->
                <span v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.RECURRENCE && booking.booking_record.recurrence">
                  {{ $parseCronString(booking.bookingRecord.recurrence, booking.bookingRecord.recur_until) }}
                </span>
                <!-- BOOKING STATUS -->
                <span v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.BOOKING_STATUS">
                  {{ $t(`booking_status.${booking.status}`) }}
                </span>
                <!-- Account Select -->
                <v-list dense
                        v-if="item.key === ITEM_TYPE_LAYOUT_KEYS.BOOKING_HOSTS">
                  <v-list-item v-for="host in booking.hosts">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ host.name }}
                        <small v-if="host.id === booking.account.id"
                               class="text--disabled">(Creator)</small>
                      </v-list-item-title>
                      <v-list-item-subtitle v-for="field in filteredFields(host, item.fields)">
                        {{ field }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- RSVP -->
                <v-list dense
                        v-if="item.key === ITEM_TYPE_LAYOUT_KEYS.BOOKING_RSVP">
                  <v-list-item v-for="invitation in bookingInvitations">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ invitation.account.name }}
                        <small v-if="!item.disable_rsvp_role && invitation.role === INVITATION_ROLE.CHAIR"
                               class="text--disabled">(Chair)</small>
                      </v-list-item-title>
                      <v-list-item-subtitle v-for="field in filteredFields(invitation.account, item.fields)">
                        {{ field }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- ITEM ATTRIBUTE -->
                <span v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.CUSTOM_ATTRIBUTE">{{ itemAttributeMap[item.id] ? itemAttributeMap[item.id].value : 'No'}}</span>
                <!-- ADDITIONAL ITEM -->
                <span v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.ADDITIONAL_ITEM">{{ bookingAdditionalItemMap[item.id] ? bookingAdditionalItemMap[item.id].value : 'N/A' }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="showRsvpActions && myInvitation">
        Attend?
        <v-spacer></v-spacer>
        <v-btn text
               :color="myInvitation.status === INVITATION_STATUS.ACCEPTED ? 'primary' : null"
               @click="rsvp(INVITATION_STATUS.ACCEPTED)">
          Yes
        </v-btn>
        <v-btn text
               :color="myInvitation.status === INVITATION_STATUS.DECLINED ? 'primary' : null"
               @click="rsvp(INVITATION_STATUS.DECLINED)">
          No
        </v-btn>
        <v-btn text
               :color="myInvitation.status === INVITATION_STATUS.TENTATIVE ? 'primary' : null"
               @click="rsvp(INVITATION_STATUS.TENTATIVE)">
          Maybe
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import _ from "lodash";
import {INVITATION_ROLE, INVITATION_STATUS, ITEM_TYPE_LAYOUT_KEYS, ITEM_TYPE_LAYOUT_TYPES} from "@/constants";
import EditRecurringDialog from "@/components/EditRecurringDialog";
import {ACTION_TYPES} from "@/store/types";
import {ItemApprover} from "@/store/models";
import EditBookingAdditionalItemDIalog from "@/components/EditBookingAdditionalItemDIalog";

export default {
  name: 'BookingDetailModal',
  props: {
    booking: Object,
    shown: Boolean,
    showRsvpActions: Boolean,
    hideEdit: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);

    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });

    watch(() => dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      }
    })

    const account = computed(() => {
      return root.$store.getters.account;
    });

    const editBooking = function () {
      root.$router.push({name: 'booking-details', params: {bookingId: props.booking.id}});
    };

    const managedItemIds = computed(() => {
      let itemApprovers = ItemApprover.query().where('user_id', account.value.id).all();
      return itemApprovers.map((a) => a.item_id);
    })
    const shouldShowCancel = computed(() => {
      return !props.hideEdit && props.booking && managedItemIds.value.indexOf(props.booking.item.id) !== -1;
    });
    const cancelBooking = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.item_admin_cancel_confirm_msg'), showClose: false})) {
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/managed_items/cancel_booking/',
          params: {
            booking_id: props.booking.id,
          },
        })

        if (response.body.success) {
          root.$showAlertMessage(root.$t('booking.item_admin_cancel_success_msg'));
          emit('dismiss', true);
        }
      }
    };

    const shouldShowEditAdditionalItem = computed(() => {
      return !props.hideEdit && props.booking && managedItemIds.value.indexOf(props.booking.item.id) !== -1 && props.booking.additional_items.length > 0;
    })
    const editAdditionalItem = async function () {
      const result = await root.$dialog.showAndWait(EditBookingAdditionalItemDIalog, {
        booking: props.booking,
        showClose: false,
        scrollable: true,
      });

      if (result) {
        emit('dismiss', true);
      }
    }

    const getRoleIdx = function (i) {
      return {
        [INVITATION_ROLE.CHAIR]: 1,
        [INVITATION_ROLE.REQUIRED_PARTICIPANT]: 2,
        [INVITATION_ROLE.OPTIONAL_PARTICIPANT]: 3,
        [INVITATION_ROLE.NON_PARTICIPANT]: 4,
      }[i.role] || 5;
    };
    const bookingInvitations = computed(() => {
      if (!props.booking) return [];

      return _.sortBy(
          _.filter(props.booking.invitations, (i) => i.status !== INVITATION_STATUS.CANCELLED),
          [getRoleIdx, (i) => {
            return {
              [INVITATION_STATUS.ACCEPTED]: 0,
              [INVITATION_STATUS.TENTATIVE]: 1,
              [INVITATION_STATUS.DECLINED]: 2,
              [INVITATION_STATUS.NEEDS_ACTION]: 3,
            }[i.status]
          }, 'name']);
    })
    const bookingInvitationDescriptions = computed(() => {
      let counts = _.countBy(_.filter(bookingInvitations.value, (i) => i.status !== INVITATION_STATUS.NEEDS_ACTION), 'status');

      return _.keys(counts).map((status) => {
        return `${counts[status]} ${{
          [INVITATION_STATUS.NEEDS_ACTION]: ' awaiting',
          [INVITATION_STATUS.ACCEPTED]: ' yes',
          [INVITATION_STATUS.DECLINED]: ' no',
          [INVITATION_STATUS.TENTATIVE]: ' maybe',
        }[status]}`;
      }).join(', ');
    })
    const invitationIcon = function (invitation) {
      return {
        [INVITATION_ROLE.CHAIR]: 'mdi-chair-rolling',
        [INVITATION_ROLE.REQUIRED_PARTICIPANT]: 'mdi-account-star',
        [INVITATION_ROLE.OPTIONAL_PARTICIPANT]: 'mdi-account-question',
        [INVITATION_ROLE.NON_PARTICIPANT]: 'mdi-account-remove',
      }[invitation.role];
      // return {
      //   [INVITATION_STATUS.NEEDS_ACTION]: 'fas fa-clock',
      //   [INVITATION_STATUS.ACCEPTED]: 'fas fa-check',
      //   [INVITATION_STATUS.DECLINED]: 'fas fa-times',
      //   [INVITATION_STATUS.TENTATIVE]: 'fas fa-question',
      // }[invitation.status];
    };
    const myInvitation = computed(() => {
      if (!props.booking || !account.value) return false;

      return _.head(_.filter(props.booking.invitations, (i) => i.account.id === account.value.id));
    });
    const rsvp = async function (status) {
      let edit = 'this';

      if (props.booking.bookingRecord.recurrence) {
        edit = await root.$dialog.showAndWait(EditRecurringDialog, {title: 'RSVP recurring booking'});
        if (edit === undefined || edit === false) return;
      }

      await root.$store.dispatch(ACTION_TYPES.RSVP_INVITATION, {
        id: myInvitation.value.id,
        response: status,
        edit,
      })

      await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('invitation.rsvp_success_msg', {
        status: root.$t(`invitation_status.${status}`),
        title: props.booking.title
      }));
    };

    const itemTypeLayoutDefinitions = computed(() => {
      if (props.booking.item.type) {
        return JSON.parse(props.booking.item.type.booking_detail_layout_definition);
      }
      return [];
    })
    const filteredItems = computed(() => {
      return _.filter(itemTypeLayoutDefinitions.value, (d) => {
        if (d.key === ITEM_TYPE_LAYOUT_KEYS.BOOKING_RECURRENCE) {
          return !!props.booking.booking_record.recurrence;
        }

        if (d.key === ITEM_TYPE_LAYOUT_KEYS.BOOKING_RSVP) {
          return bookingInvitations.value.length;
        }

        return true;
      })
    });

    const getDisplayValue = function (item) {
      return {
        [ITEM_TYPE_LAYOUT_KEYS.ITEM_TYPE_NAME]: props.booking.item.type.name,
        [ITEM_TYPE_LAYOUT_KEYS.ITEM_NAME]: props.booking.item.name,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_TITLE]: props.booking.title,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_DATE]: props.booking.start_time,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_START_TIME]: props.booking.start_time,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_END_TIME]: props.booking.end_time,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_CREATE_DATE]: props.booking.created_at,
      }[item.key];
    }

    const bookingAdditionalItems = computed(() => {
      if (props.booking) {
        return _.sortBy(props.booking.additional_items, (i) => i.item_type_additional_item.ordering);
      }

      return [];
    })
    const bookingAdditionalItemMap = computed(() => {
      let result = {};

      bookingAdditionalItems.value.forEach((i) => {
        result[i.item_type_additional_item.id] = i;
      })

      return result;
    })

    const itemAttributes = computed(()=> {
      if (props.booking) {
        return props.booking.item.attributes;
      }

      return [];
    });
    const itemAttributeMap = computed(()=> {
      let result = {};

      itemAttributes.value.forEach((a) => {
        result[a.type_attribute] = a;
      })

      return result;
    })

    const filteredFields = function (account, fields) {
      let results = [];

      fields.forEach((f) => {
        if (account[f]) {
          results.push(account[f]);
        }
      })

      return results;
    }

    return {
      dialogShown,
      account,
      editBooking,
      shouldShowCancel,
      cancelBooking,
      shouldShowEditAdditionalItem,
      editAdditionalItem,
      bookingInvitations,
      bookingInvitationDescriptions,
      invitationIcon,
      myInvitation,
      rsvp,

      bookingAdditionalItems,

      itemTypeLayoutDefinitions,
      filteredItems,
      getDisplayValue,
      bookingAdditionalItemMap,
      filteredFields,
      itemAttributeMap,
      ITEM_TYPE_LAYOUT_TYPES,
      ITEM_TYPE_LAYOUT_KEYS,

      INVITATION_ROLE,
      INVITATION_STATUS,
    }
  },
}
</script>
