import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BGModal',{attrs:{"shown":_vm.dialogShown,"title":_vm.$t('instant_book.title'),"max-width":"500px","no-fullscreen":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VContainer,{staticClass:"search",attrs:{"fluid":""}},[_c('AvailabilityGrid',{attrs:{"start-date":_vm.selectedDate,"end-date":_vm.selectedDate,"start-time":_vm.selectedFromTime,"extra-params":{
                          item_id: _vm.itemId,
                        },"show-booking-details":"","refresh-trigger":_vm.refreshTrigger},on:{"update:refreshTrigger":function($event){_vm.refreshTrigger=$event},"update:refresh-trigger":function($event){_vm.refreshTrigger=$event}},model:{value:(_vm.selectedSessionIds),callback:function ($$v) {_vm.selectedSessionIds=$$v},expression:"selectedSessionIds"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"secondary","depressed":"","large":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]),_c(VSpacer),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"primary","disabled":_vm.selectedSessionIds.length === 0,"depressed":"","large":""},on:{"click":_vm.book}},[_vm._v("BOOK ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }