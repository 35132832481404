import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pending-approval",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('DatePickerInput',{attrs:{"range":"","show-from-today":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{attrs:{"outlined":"","clearable":"","hide-details":"","label":_vm.$t('booking.item'),"items":_vm.itemOptions},model:{value:(_vm.filterItemId),callback:function ($$v) {_vm.filterItemId=$$v},expression:"filterItemId"}})],1)],1),_c('RemotePaginatedList',{attrs:{"full-height":"","headers":_vm.headers,"multi-sort":"","hide-default-footer":"","items-per-page":-1,"data-url":"client/pending_approval/","data-key":"bookings","extra-params":_vm.extraParams,"extra-filters":_vm.extraFilters,"refresh-trigger":_vm.refreshTrigger},on:{"click:row":_vm.onBookingClicked,"update:refreshTrigger":function($event){_vm.refreshTrigger=$event},"update:refresh-trigger":function($event){_vm.refreshTrigger=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'yyyy-MM-dd HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.name)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }