import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"app"}},[(_vm.account)?_c('AppDrawer',{model:{value:(_vm.drawerShown),callback:function ($$v) {_vm.drawerShown=$$v},expression:"drawerShown"}}):_vm._e(),_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","id":"appBar","hide-on-scroll":_vm.$vuetify.breakpoint.mdAndDown,"scroll-threshold":"56"},model:{value:(_vm.appBarShown),callback:function ($$v) {_vm.appBarShown=$$v},expression:"appBarShown"}},[(_vm.account && _vm.$vuetify.breakpoint.mdAndDown)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawerShown = !_vm.drawerShown}}}):_vm._e(),_c('div',{staticClass:"app-bar-title"},[_vm._v(_vm._s(_vm.appBarTitle))]),_c(VSpacer)],1),_c(VMain,[_c('router-view')],1),_c(VSnackbar,{attrs:{"timeout":3000},model:{value:(_vm.snackBarShown),callback:function ($$v) {_vm.snackBarShown=$$v},expression:"snackBarShown"}},[_vm._v(" "+_vm._s(_vm.snackBarContent)+" ")]),_c(VOverlay,{attrs:{"value":_vm.isLoading,"z-index":"9999"}},[_c('GridLoader')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }