import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"availability-grid"},[_c(VCardSubtitle,[_c('div',[_vm._v(_vm._s(_vm.cardTitle))]),_c('div',{staticClass:"remove-legend-wrapper"},[_vm._v(_vm._s(_vm.$t('booking.edit_time_remove_description'))+" "),_c('div',{staticClass:"remove-legend"})])]),_c(VCardText,{staticStyle:{"height":"70vh"},attrs:{"id":"changeBookingTimeDialog"}},[(_vm.booking && _vm.filteredSessions.length)?_c(VCalendar,{attrs:{"type":"category","interval-height":"32","interval-width":"45","first-interval":_vm.firstInterval,"interval-count":_vm.intervalCount,"interval-minutes":"30","interval-format":_vm.getIntervalFormat,"start":_vm.bookingDate,"end":_vm.bookingDate,"categories":_vm.categories,"events":_vm.filteredSessions,"event-color":_vm.getSessionColor,"event-overlap-threshold":1},on:{"click:event":_vm.sessionClicked},scopedSlots:_vm._u([{key:"category",fn:function(ref){
var category = ref.category;
return [_c(VContainer,{staticClass:"text-center fixed-categories",attrs:{"fluid":""},on:{"click":function($event){return _vm.$emit('click:category', {event: $event, item: _vm.itemMap[category], item_id: category})}}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VIcon,{attrs:{"color":"rgb(40, 97, 169)"}},[_vm._v("mdi-information-outline")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.itemMap[category].name)+" ")])])],1)],1)]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c(VContainer,{class:['font-weight-bold', 'text-center', 'pa-0', 'fill-height', 'flex-column', {'required-approval': event.require_approval}, {'target-booking': event.booking && event.booking.id === _vm.booking.id}],attrs:{"fluid":""}},[_c(VRow,{staticClass:"flex-grow-1 d-flex",staticStyle:{"width":"100% !important"},attrs:{"no-gutters":""}},[(event.booking)?_c(VCol,{staticClass:"availability-grid-event-content fill-height",domProps:{"innerHTML":_vm._s(_vm.eventLabel(event))}}):_vm._e(),(event.pending_bookings && event.pending_bookings.length > 0)?_c('div',{staticClass:"availability-grid-pending-bookings"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.showPendingBookings(event)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-clock-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(((_vm.$t('booking_status.PENDING_APPROVAL')) + ": " + (event.pending_bookings.length)))+" ")])],1):_vm._e()],1)],1)]}}],null,false,1608025728)}):_vm._e()],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary","disabled":!_vm.canSave},on:{"click":function($event){_vm.confirmDialogShown = true}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1),_c(VOverlay,{model:{value:(_vm.confirmDialogShown),callback:function ($$v) {_vm.confirmDialogShown=$$v},expression:"confirmDialogShown"}}),_c('BookingDetailModal',{attrs:{"shown":_vm.bookingDetailModalShown,"booking":_vm.showingBooking,"hide-edit":""},on:{"dismiss":function($event){_vm.bookingDetailModalShown = false}}}),_c(VDialog,{attrs:{"attach":"#changeBookingTimeDialog","max-width":"480px","hide-overlay":""},model:{value:(_vm.confirmDialogShown),callback:function ($$v) {_vm.confirmDialogShown=$$v},expression:"confirmDialogShown"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.$t('booking.edit_time_confirmation_msg', {from: _vm.originalTimePeriod, to: _vm.selectedSessionTimeLabel}))}},[_vm._v(_vm._s(_vm.originalTimePeriod)+" "+_vm._s(_vm.selectedSessionTimeLabel))]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.confirmDialogShown = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }