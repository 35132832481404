import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BannerFrame',{attrs:{"label":_vm.$t('booking_record.detail_title')}},[(_vm.bookingRecord)?_c(VContainer,{staticClass:"booking-detail"},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('booking.created_at'),"readonly":"","hide-details":"","value":_vm.$parseDate(_vm.bookingRecord.created_at)}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('booking_record.recurrence'),"readonly":"","hide-details":"","value":_vm.$parseCronString(_vm.bookingRecord.recurrence, _vm.bookingRecord.recur_until)}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"label":_vm.$t('booking.status'),"readonly":"","hide-details":"","value":_vm.$t(("booking_record_status." + (_vm.bookingRecord.status))),"rows":"1","auto-grow":"","no-resize":""}})],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.bookings,"mobile-breakpoint":"0"},on:{"click:row":_vm.onBookingClicked},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value, 'yyyy-MM-dd'))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("booking_status." + value)))+" ")]}}],null,false,150616236)})],1)],1),_c(VFooter,{staticClass:"pr-6 py-3",attrs:{"app":"","inset":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.cancelBooking}},[_vm._v(" "+_vm._s(_vm.$t('booking_record.cancel'))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }