<template>
  <BGModal :shown="dialogShown"
           :title="item && item.name"
           max-width="640px"
           :persistent="false"
           @dismiss="cancel"
           no-fullscreen>
    <template #content>
      <v-container fluid class="item-detail-modal-content pa-0" v-if="item">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Location
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="font-weight-bold justify-end">
            {{ item.region.location.name }}
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="showRegion">
          <v-list-item-content>
            <v-list-item-title>
              Region
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="font-weight-bold justify-end">
            {{ item.region.name }}
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="showRegion"></v-divider>
        <v-row v-if="item && item.descriptions" class="px-4">
          <v-col cols="12">
            <h3>{{ $t('item.descriptions') }}</h3>
          </v-col>
          <v-col cols="12" v-html="item.descriptions"></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col v-for="attribute in attributesWithoutCategory"
                 :key="attribute.id"
                 :cols="12"
                 :md="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN ? 6 : 12">

            <v-list-item v-if="attribute.type !== ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN">
              <v-list-item-content>
                <v-list-item-title>
                  {{ attribute.name }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon class="font-weight-bold justify-end">
                {{
                  attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.DATE || attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.DATE_RANGE ? $parseDate(itemAttributeMap[attribute.id]) : (itemAttributeMap[attribute.id] || 'N/A')
                }}
              </v-list-item-icon>
            </v-list-item>

            <v-list-item v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN">
              <!--              <v-list-item-icon>-->

              <!--              </v-list-item-icon>-->
              <v-list-item-content
                  :class="{'disabled': !itemAttributeMap[attribute.id] || itemAttributeMap[attribute.id] !== 'True'}">
                {{ attribute.name }}
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row v-if="item.itemAdmins.length > 0">
          <v-col cols="12" class="section-header">
            <div class="section-header-background"></div>
            Admin Contacts
          </v-col>
          <v-col cols="12"
                 v-for="approver in item.itemAdmins">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ approver.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="approver.title">
                  {{ approver.title }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="approver.mobile">
                  {{ approver.mobile }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="approver.email">
                  {{ approver.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row v-for="attributeGroup in attributeGroups"
               :key="attributeGroup.category">
          <v-col cols="12" class="section-header">
            <div class="section-header-background"></div>
            {{ attributeGroup.category }}
          </v-col>
          <v-col v-for="attribute in getAttributeGroupAttributes(attributeGroup)"
                 :key="attribute.id"
                 :cols="12"
                 :class="[{'py-0': attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN}]"
                 :md="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN ? 6 : 12">

            <v-list-item v-if="attribute.type !== ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN">
              <v-list-item-content>
                <v-list-item-title>
                  {{ attribute.name }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon class="font-weight-bold justify-end">
                {{
                  attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.DATE || attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.DATE_RANGE ? $parseDate(itemAttributeMap[attribute.id]) : (itemAttributeMap[attribute.id] || 'N/A')
                }}
              </v-list-item-icon>
            </v-list-item>

            <v-list-item v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN"
                         :class="{'disabled': !itemAttributeMap[attribute.id] || itemAttributeMap[attribute.id] !== 'True'}">
              <v-list-item-icon>
                <v-icon color="primary">
                  {{
                    !itemAttributeMap[attribute.id] || itemAttributeMap[attribute.id] !== 'True' ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked'
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ attribute.name }}
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #footer>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn color="secondary"
               @click="cancel"
               class="mr-3"
               depressed
               large>
          {{ $t('close') }}
        </v-btn>
      </v-row>
    </template>
  </BGModal>
</template>

<script>
import BGModal from "@/components/BGModal";
import {computed, ref, watch} from "@vue/composition-api";
import {Item, Region} from "@/store/models";
import _ from "lodash";
import {ITEM_TYPE_ATTRIBUTE_TYPE} from "@/constants";

export default {
  name: 'ItemDetailModal',
  components: {BGModal},
  props: {
    shown: Boolean,
    itemId: String,
  },
  setup: function (props, {emit}) {
    const dialogShown = ref(props.shown);

    // Init
    watch(() => props.shown, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });
    const cancel = function () {
      emit('cancel');
    };

    //
    const item = computed(() => Item.query().withAllRecursive().whereId(props.itemId).first());
    const itemType = computed(() => item.value?.type);
    const showRegion = computed(() => {
      if (item.value) {
        const regionIds = Region.query().where('location_id', item.value.region.location_id).get().map((r) => r.id);

        const items = Item.query().where('type_id', item.value.type_id).where((i) => regionIds.indexOf(i.region_id) !== -1).get();

        return _.uniqBy(items, 'region_id').length > 1;
      }

      return true;
    })

    // Attributes
    const itemAttributeMap = computed(() => {
      let map = {};

      if (item.value) {
        item.value.attributes.forEach((a) => {
          map[a.typeAttribute.id] = a.value;
        })
      }

      return map;
    })
    const attributesWithoutCategory = computed(() => {
      if (itemType.value) {
        return _.sortBy(_.filter(itemType.value.attributes, (a) => !a.category), ['ordering', 'name'])
      }

      return [];
    })
    const attributeGroups = computed(() => {
      if (itemType.value) {
        let itemTypeAttributes = itemType.value.attributes;

        let attributesWithoutCategory = [];
        let categories = {};

        itemTypeAttributes.forEach((a) => {
          if (a.category) {
            if (!(a.category in categories)) {
              categories[a.category] = [];
            }
            categories[a.category].push(a);
          } else {
            attributesWithoutCategory.push(a);
          }
        });

        return Object.keys(categories).map((category) => {
          return {category, attributes: _.sortBy(categories[category], ['ordering', 'name'])};
        });

        // return [{
        //   category: '',
        //   attributes: _.sortBy(attributesWithoutCategory, ['ordering', 'name'])
        // }].concat(Object.keys(categories).map((category) => {
        //   return {category, attributes: _.sortBy(categories[category], ['ordering', 'name'])};
        // }));
      }

      return [];
    });
    const getAttributeGroupAttributes = function (group) {
      return _.sortBy(group.attributes, [(a) => {
        if (a.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN) {
          return itemAttributeMap.value[a.id] && itemAttributeMap.value[a.id] === 'TRUE';
        } else {
          return itemAttributeMap.value[a.id]
        }
      }, 'name'])
    };

    return {
      dialogShown,
      cancel,

      item,
      itemType,
      showRegion,

      itemAttributeMap,
      attributesWithoutCategory,
      attributeGroups,
      getAttributeGroupAttributes,

      ITEM_TYPE_ATTRIBUTE_TYPE,
    }
  }
}
</script>

<style lang="less">
.item-detail-modal-content {
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;

  .section-header {
    position: relative;
    text-align: center;
    font-weight: bold;

    .section-header-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--v-primary-base);
      opacity: .11;
    }
  }

  .disabled {
    filter: grayscale(100%);
    opacity: .2;
  }
}
</style>
